import {put, takeEvery} from 'redux-saga/effects'
import {
    CREATE_NEW_ALBUM, DELETE_ALBUM, DELETE_TRACK, DELETE_TRACKS,
    GET_ALBUM_LIST,

    GET_ALBUM_LIST_UNCHECKED,
    GET_DATA_ALBUM, GET_OUTSIDE_SECTION, RENAME_ALBUM,
    SET_ALBUM_STATUS, UPDATE_COVER_ALBUM,
} from "../types";
import {
    getAlbumList, getAlbumListUnchecked, getDataAlbum,
    setAlbumList, setAlbumListUnchecked,
    setDataAlbum, setLastOrder,
    setTracksAlbum,

} from "../actions/albumActions";

import {transformAlbumList, transformDataAlbum, transformTrackAlbum} from "../../utils/transformAPI";
import {
    gd__AlbumListUnchecked,
    gd__Album,
} from "../../api/getData";
import {
    sd__albumStatus,

} from "../../api/setData";
import {setLoadingFalse, setLoadingTrue} from "../actions/infoActions";
import {getAvailableList} from "../actions/propsActions";
import {albumAPI} from "../../api/API";
import {renameAlbumAPI, updateCoverAlbumAPI} from "../../api/albumAPI";
import {onMessage} from "../../utils/onMessage";

export function* albumSagaWatcher() {
    yield takeEvery(GET_DATA_ALBUM, sagaGetAlbum)
    yield takeEvery(GET_ALBUM_LIST_UNCHECKED, sagaGetAlbumListUnchecked)
    yield takeEvery(SET_ALBUM_STATUS, sagaSetAlbumStatus)
    yield takeEvery(GET_ALBUM_LIST, sagaGetAlbumList)
    yield takeEvery(CREATE_NEW_ALBUM, createNewAlbum)
    yield takeEvery(DELETE_ALBUM, deleteAlbum)
    yield takeEvery(UPDATE_COVER_ALBUM, updateCoverAlbum)
    yield takeEvery(RENAME_ALBUM, renameAlbum)
}

function* sagaGetAlbumListUnchecked() {
    let albumList = yield gd__AlbumListUnchecked()
    yield put(setAlbumListUnchecked(albumList));
}

function* sagaGetAlbumList() {
    yield put(setLoadingTrue)
    let albumList = yield albumAPI.getAlbumList()
    yield put(setAlbumList(transformAlbumList(albumList.data)))
    yield put(setLoadingFalse)
}

function* sagaSetAlbumStatus({payload}) {
    console.log(payload.idAlbum, payload.checked)
    let albumList = yield sd__albumStatus({idAlbum: payload.idAlbum, checked: payload.checked});
    yield sagaGetAlbum({payload: payload.idAlbum});
}

function* sagaGetAlbum({payload}) {
    console.log('sagaGetAlbum', payload)
    let album = yield gd__Album(payload)
    yield put(setDataAlbum(transformDataAlbum(album)))
    let tracks = yield transformTrackAlbum(album)
    yield put(setTracksAlbum(tracks))
    yield put(getAvailableList(album.idTypeAudio))
    yield put(setLastOrder(tracks.length))
}

function* createNewAlbum({payload}) {
    let response = yield albumAPI.new(payload)
    yield onMessage(response)
    yield put(getAlbumListUnchecked())
}

function* deleteAlbum({payload}) {
    let response = yield albumAPI.delete(payload)
    yield onMessage(response)
    yield put(getAlbumList())
    yield put(getAlbumListUnchecked())
}

function* updateCoverAlbum({payload}) {
    let response = yield updateCoverAlbumAPI(payload)
    yield onMessage(response, 'обложка обновленна')
    yield put(getDataAlbum(payload.idAlbum))
}

function* renameAlbum({payload}) {
    let response = yield renameAlbumAPI(payload)
    yield onMessage(response)
    yield put(getDataAlbum(payload.idAlbum))
}
