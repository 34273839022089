import React, {useEffect, useState} from "react";
import {Avatar, Button, Form, Input} from "antd";
import {EditAlbumCover} from "./EditAlbumCover";
import {useDispatch} from "react-redux";
import {renameAlbum} from "../../redux/actions/albumActions";

export const EditTrackForm = ({
                                  name,
                                  onFinish,
                                  form
                              }) => {

    return (
        <>
            <Form form={form} name="horizontal_login" layout="inline" onFinish={onFinish}>
                <Form.Item
                    name="newNameTrack"
                    rules={[{min: 4, message: 'Минимальная длина 4 символа'}]}
                >
                    <Input placeholder={name} defaultValue={name}/>
                </Form.Item>
                <Form.Item shouldUpdate={true}>
                    {() => (
                        <Button
                            type="primary"
                            htmlType="submit"
                            disabled={
                                !form.isFieldsTouched(true) ||
                                form.getFieldsError().filter(({errors}) => errors.length).length
                            }
                        >
                            Изменить название
                        </Button>
                    )}
                </Form.Item>
            </Form>
        </>
    )
}
