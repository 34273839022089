import {Col, Select} from "antd";
import React, {useState} from "react";
import {createPropsTrack} from "../../redux/actions/propsActions";
import {sd__trackPropNew, sd__trackPropUpdate} from "../../api/setData";
const styleHeader = {fontWeight: 'bold', fontSize: '13px', width: '100%'}
const {Option} = Select;

export const PropsSelectorGroup = ({props, width, nameGroupProp, changeGroupProps, idGroupProp, selectGroupProperty}) => {

    return (
        <Col style={{padding: "15px 5px", backgroundColor: '#FDFDFD', display: 'flex'}} span={width}>
            <Select bordered={false}

                    mode="multiple"
                    autoClearSearchValue={false}
                    notFoundContent={false}
                    optionLabelProp="label"
                    optionFilterProp="label"
                    placeholder="Выберите"
                    size={'small'}
                    style={styleHeader}
                    value={selectGroupProperty}
                    key={idGroupProp}
                    onChange={(e)=>changeGroupProps(e, idGroupProp, selectGroupProperty)}
            >
                <Option value={null} disabled>{nameGroupProp}</Option>
                {props.map(p => {
                    // return <Option value={p.idProp}>{p.name}</Option>
                    return <Option value={p.idProp} label={p.name} key={p.idProp}>{p.name}</Option>
                })}
            </Select>
        </Col>
    )
}
