import {SET_AUTH_FALSE, SET_AUTH_TRUE, SET_READY_FALSE, SET_READY_TRUE} from "../types";

let initialState = {
    isAuth: false,
    isReady: false
};

export default (state = initialState, action) => {
    switch (action.type) {
        case SET_AUTH_TRUE:
            return {...state, isAuth: true}
        case SET_AUTH_FALSE:
            return {...state, isAuth: false}
        case SET_READY_FALSE:
            return {...state, isReady: false}
        case SET_READY_TRUE:
            return {...state, isReady: true}
        default:
            return state;
    }
};
