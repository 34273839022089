import React, {useEffect, useState} from "react";
import {useParams} from "react-router-dom"
import {useDispatch, useSelector} from 'react-redux'
import {Card, Col, PageHeader, Row, Switch} from "antd";
import {deleteTrackToPlaylist, getPlaylist, updatePublicStatusPlaylist} from "../redux/actions/playlistActions";
import {Manager} from "../components/album/TrackManager";
import {SearchTrackManager} from "../components/search/SearchTrackManager";


const EditPlaylistPage = props => {
    const dispatch = useDispatch();
    let idPlaylist = useParams().id;
    let playlistTrack = useSelector(state => state.playlist.playlistTrack);
    let playlist = useSelector(state => state.playlist.playlist);
    const [isPublic, setIsPublic] = useState(false)

    useEffect(() => {dispatch(getPlaylist(idPlaylist))}, [idPlaylist]);
    useEffect(() => {
        setIsPublic(playlist.isPublic)
    }, [playlist]);

    const del = (id) => dispatch(deleteTrackToPlaylist({idTrackToPlaylist: id, idPlaylist}));

    const onChange = (data) => {
        dispatch(updatePublicStatusPlaylist({idPlaylist, 'isPublic': data}))
        setIsPublic(!isPublic)
    };
    return (
        <>
            {/*<Row gutter={16}>*/}
            {/*    <Card title="Card title" bordered={false} style={{ width: 300 }}>*/}
            {/*        <p>Card content</p>*/}
            {/*    </Card>*/}
            {/*</Row>*/}
            <Row gutter={16}>
                <Col span={12}>
                    <SearchTrackManager idPlaylist={idPlaylist}/>
                </Col>
                <Col span={12}>
                    <PageHeader
                        ghost={false}
                        onBack={() => window.history.back()}
                        title={playlist.name}
                        extra={[
                            <Switch checked={isPublic} onChange={onChange} />
                        ]}
                    >
                    <Manager
                        tracks={playlistTrack}
                        EditItem={null}
                        idAlbum={idPlaylist}
                        type={'playlist'}
                        del={del}
                    />
                    </PageHeader>
                </Col>
            </Row>
        </>
    )
};

export default EditPlaylistPage
