import {pageAPI, sectionAPI} from "./API";

export const listSectionAPI = () => {
    return sectionAPI.list().then(response => {
        return response.data;
    })
};

export const createSectionAPI = (data) => {
    return sectionAPI.create(data).then(response => {
        return response.data;
    })
};

export const addSectionsToPageAPI = (data) => {

    return pageAPI.addSections(data).then(response => {
        return response.data;
    })
};

export const addSectionToPageAPI = (data) => {

    return pageAPI.addSection(data).then(response => {
        return response.data;
    })
}

export const addAlbumsToSection = (data) => {

    return sectionAPI.AddAlbums(data).then(response => {
        return response.data;
    })
};
