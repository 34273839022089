import {Button, Form, Input, Select, Upload} from "antd";
import {UploadOutlined, InboxOutlined} from '@ant-design/icons';
import React from "react";
import {UploadDemoForm} from "./UploadDemoForm";

export const CreatePropForm = ({selectTypes, form}) => {
    const formItemLayout = {
        labelCol: {span: 6,},
        wrapperCol: {span: 14},
    };

    return (
        <Form form={form} {...formItemLayout}>
            <Form.Item
                name="IdGroupProp"
                label="Группа"
                hasFeedback
                rules={[
                    {
                        required: true,
                        message: 'Выберите тип',
                    }
                ]}
            >
                <Select
                    showSearch
                    placeholder="Выберите тип"
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }
                >
                    {selectTypes}
                </Select>
            </Form.Item>
            <Form.Item
                name="name"
                label="Свойство"
                hasFeedback
                rules={[
                    {required: true, message: 'Пожалуйтса, введите название',},
                    {min: 3, message: 'Минимальная длина 3 символа'}
                ]}
            >
                <Input placeholder="Название свойства"/>
            </Form.Item>
        </Form>
    )
}
