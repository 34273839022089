import React, {useState} from "react";
import {Input, Space, Spin, Table, Tag} from "antd";
import {getOutsideSection} from "../../redux/actions/albumActions";
const { Search } = Input;

export const AlbumSearch = ({findAlbums, objectAdd, onSearch, outsideSection}) => {

    const columns = [
        {
            title: 'id',
            dataIndex: 'idAlbum',
            key: 'idAlbum',
        },
        {
            title: 'Название',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: '',
            key: 'action',
            render: (text, record) => (
                <Space size="middle">
                    <a onClick={() => objectAdd(record.idAlbum, record.idTypeCell)} key="delete">
                        добавить
                    </a>
                </Space>
            ),
        },
    ];

    return (
        <>
            <Search placeholder="Введите название альбома" onSearch={onSearch} style={{minWidth: 300, marginBottom: 30}}/>
            {findAlbums.length > 0 && <h3>Найдено: {findAlbums.length} </h3>}
            {findAlbums.length > 0 && <Table dataSource={findAlbums}
                   columns={columns}
                   pagination={{hideOnSinglePage: true}}
                   style={{marginBottom: 30}}
            />}
            {findAlbums.length < 1 && <h3>Список всех доступных альбомов:</h3>}
            {findAlbums.length < 1 && <Table dataSource={outsideSection}
                    columns={columns}
                    pagination={{hideOnSinglePage: true}}
                    style={{marginBottom: 30}}
            />}
        </>
    )
}




