import React from "react";
import {Button} from "antd";
import {EditOutlined, BarsOutlined} from '@ant-design/icons';

export const UploadBar = ({showModal, loading, toggleChange, showModalEdit}) => [
    <Button key='1' onClick={showModal} disabled={loading} type="dashed" danger>
        открыть окно загрузки <BarsOutlined/>
    </Button>,
    <Button key='2' onClick={toggleChange}>
        переключить
    </Button>,
    <Button key='3' onClick={showModalEdit}>редактировать альбом <EditOutlined key="edit"/></Button>,
]
