import {albumAPI, performerAPI, propsAPI} from "./API";

export const gd__AlbumList = () => {
    return albumAPI.getAlbumList().then(response => {
        return response.data;
    })
}

export const gd__Album = (id) => {
    return albumAPI.getAlbum(id).then(response => {
        return response.data;
    });
}

export const gd__AlbumListUnchecked = () => {
    return albumAPI.getAlbumListUnchecked().then(response => {
        return response.data;
    });
}

export const gd__PropsGroupList = () => {
    return propsAPI.groupList().then(response => {
        return response.data;
    });
}

export const gd__PropsAvailable = (id) => {
    return propsAPI.groupAvailable(id).then(response => {
        return response.data;
    });
}

export const gd__TrackProp = (id) => {
    return propsAPI.getTrackProp(id).then(response => {
        return response.data;
    });
}

export const gd__TrackListProp = (idAlbum) => {
    return propsAPI.getTrackPropList(idAlbum).then(response => {
        return response.data;
    });
}

export const gd__ListPerformer = () => {
    return performerAPI.getList().then(response => {
        return response.data;
    });
}
