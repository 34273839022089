import React from "react";
import {useDispatch} from "react-redux";
import {deleteTrack} from "../../redux/actions/albumActions";
import {Col, Popconfirm, Row} from "antd";
import {Draggable} from "react-beautiful-dnd";
import {convertHMS} from "../../utils/convertHMS";
import {CaretRightFilled, QuestionCircleOutlined, EditOutlined, DeleteOutlined} from '@ant-design/icons';

export const Quote = ({quote, index, EditItem, idAlbum, del, type}) => {

    const popItem = (
        <Popconfirm title="Удалить трек？"
                    icon={<QuestionCircleOutlined style={{color: 'red'}}/>}
                    onConfirm={() => del(quote.content.idTrack)}
                    okText="да"
                    cancelText="нет"
        >
            <DeleteOutlined />
        </Popconfirm>
    );

    const playItem = (
        <div onClick={
            () => {
                // playPauseAudio(quote.content.idTrack, quote.content.link)
                // newQueue(quote.content.idTrack, quote.content.link)
                // value(quote.content.idTrack, idAlbum)
            }
        }><CaretRightFilled/></div>
    );

    const editItem = (
        <EditOutlined onClick={() => {
            EditItem(quote.content.idTrack, quote.content.name)
        }} />
    );

    return (
        <Draggable
            draggableId={quote.id}
            index={index}
            EditItem={EditItem}
        >
            {provided => (
                <div
                    style={{padding: '10px', backgroundHover: '#FBFBFB'}}
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                >
                    <Row>
                        <Col span={1}>{index + 1}</Col>
                        <Col span={1}>{playItem}</Col>
                        <Col span={14}>{quote.content.name}</Col>
                        <Col span={2}/>
                        <Col span={2}>{convertHMS(quote.content.duration)}</Col>
                        <Col span={2}/>
                        {type === 'album' && <Col span={1}>{editItem}</Col>}
                        <Col span={1}>{popItem}</Col>
                    </Row>
                </div>
            )}
        </Draggable>
    );
};
