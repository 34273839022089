import {put, takeEvery} from 'redux-saga/effects'

import {setSectionList} from "../actions/sectionActions";
import {GET_PAGE_LIST, UPDATE_ORDER_SECTION_TO_PAGE} from "../types";
import {listPageAPI} from "../../api/pageAPI";
import {setPageList} from "../actions/pageActions";
import {pageAPI} from "../../api/API";

export function* pageSagaWatcher() {
    yield takeEvery(GET_PAGE_LIST, sagaGetList);
    yield takeEvery(UPDATE_ORDER_SECTION_TO_PAGE, updateOrderSection)
}

function* sagaGetList() {
    let response = yield listPageAPI();
    yield put(setPageList(response))
}

function* updateOrderSection({payload}) {
    const newOrder = payload.map((s, i) => {
       return ( {IdPageSection: s.content.idSectionPage, IdNewOrder: i})
    });
    pageAPI.updateOrders(newOrder)
}

