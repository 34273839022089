import {albumAPI} from "./API";

export const deleteAlbumAPI = (id) => {
    return albumAPI.delete(id).then(response => {
        return response;
    })
}

export const updateCoverAlbumAPI = (object) => {
    return  albumAPI.updateCover(object).then(response => {
        return response;
    })
}

export const renameAlbumAPI = (object) => {
    return albumAPI.rename(object).then(response => {
        return response;
    })
}
