import {call, put, takeEvery, select} from 'redux-saga/effects'
import {
    GET_AVAILABLE_PROPS,
    GET_GROUP_PROPS, GET_PROPS_TRACK_LIST,
    GET_QUEUE,

} from "../types";
import {
setPropsTracksList
} from "../actions/albumActions";

import {
    selectAlbumData,
    selectAlbumTracks,
} from "../selectors";
import {setIdQueue, setQueue} from "../actions/queueActions";

import {
    gd__Album,
    gd__PropsGroupList,
gd__PropsAvailable, gd__TrackListProp
} from "../../api/getData";

import { setAvailableList, setGroupList} from "../actions/propsActions";

export function* sagaWatcher() {
    yield takeEvery(GET_QUEUE, sagaGetQueue)
    yield takeEvery(GET_GROUP_PROPS, sagaPropsGroupList)
    yield takeEvery(GET_AVAILABLE_PROPS, sagaPropsAvailable)
    yield takeEvery(GET_PROPS_TRACK_LIST, sagaGetListProp)
}

function* sagaGetQueue({payload}) {
    const albumData = yield select(selectAlbumData);
    const albumTracks = yield select(selectAlbumTracks);
    console.log(albumData.idAlbum, payload)
    if (albumData.idAlbum == payload) {
        console.log('текущий альбом')
        yield put(setQueue(albumTracks))
    } else {
        let album = yield gd__Album(payload)
        yield put(setQueue(album.tracks))
    }
    yield put(setIdQueue(payload))
}
function* sagaPropsGroupList() {
    let groupList = yield gd__PropsGroupList()
    yield put(setGroupList(groupList))
}
function* sagaPropsAvailable({payload}) {
    let groupList = yield gd__PropsAvailable(payload)
    yield put(setAvailableList(groupList))
}
function* sagaGetListProp({payload}) {
    let response = yield gd__TrackListProp(payload)
    yield put(setPropsTracksList(response))
}



