import React from "react";
import {Input, Select, Radio, Tag} from 'antd';

export const FilterSearch = ({optionsTags, radioOptions, onChangeType, onChangeProps}) => {

    const options = [{ value: 'gold' }, { value: 'lime' }, { value: 'green' }, { value: 'cyan' }];

    function tagRender(props) {
        const { label, value, closable, onClose } = props;

        return (
            <Tag closable={closable} onClose={onClose} style={{ marginRight: 3 }}>
                {label}
            </Tag>
        );
    }

    return (
        <>
            <br/><br />
            <Radio.Group onChange={onChangeType} defaultValue>
                {/*<Radio.Button value="a">Не выбранно</Radio.Button>*/}
                {radioOptions}
            </Radio.Group>
            <br/>
            <br />
            <Select
                mode="multiple"
                tagRender={tagRender}
                labelInValue
                onChange={onChangeProps}
                // onSelect={onChangeProps}
                // defaultValue={null}
                filterOption={false}
                placeholder={'Введите свойство'}
                style={{ width: '100%' }}
                options={optionsTags}
            />
        </>
    )
}
