import React from "react";
import {Col, Row} from "antd";

export const TrackList = ({data, addToPlaylist}) => {
    return (
        <>
            {
                data.map(t => {
                    return (
                        <Row key={t.content.idTrack}>
                            <Col span={16}>{t.content.name}</Col>
                            <Col span={8}><a onClick={()=>{addToPlaylist(t.content.idTrack)}}>добавить в плейлист</a></Col>
                        </Row>
                    )
                })
            }
        </>
    )
}
