import React from 'react';
import {Button, Menu, Layout, PageHeader, Row, Col, Typography} from 'antd';
import 'antd/dist/default-theme';
import {logout} from "../../redux/actions/authActions";
import {useDispatch} from "react-redux";
import {Player} from "../player/Player";

const {Title} = Typography;

export const Header = () => {
    const {Header} = Layout;
    const dispatch = useDispatch()

    const logoutHandler = (event) => {
        event.preventDefault()
        dispatch(logout())
    }

    return (
        <div className="site-page-header-ghost-wrapper">
            <Header className="header" style={{backgroundColor: '#fff'}}>
                <Row align='middle' justify='space-between'>
                    <Col span={4} justify='start'><h3>StudioSib</h3></Col>
                    <Col span={4}></Col>
                    <Col span={4}></Col>
                    <Col span={8}><Player/></Col>
                    <Col span={2}></Col>
                    <Col span={2} flex={{'flex-direction': 'row-reverse'}}>
                        <Button key="1" onClick={logoutHandler}>
                            Выйти
                        </Button>
                    </Col>
                </Row>
            </Header>
        </div>
    )
}
