import {SET_AUDIT_LIST, SET_AUDIT_LIST_AUTH} from "../types";
import {auditAPI} from "../../api/API";
import {transformAudit, transformAuditAuth} from "../../utils/transformAPI";

export const setAuditList = (payload) => ({type: SET_AUDIT_LIST, payload});
export const setAuditListAuth = (payload) => ({type: SET_AUDIT_LIST_AUTH, payload});

export const getAuditList = (type) => (dispatch) => {
    auditAPI.getAudit(type).then(response => {
        console.log(response)
        let audit = response.data.map(arr => transformAudit(arr))
        dispatch(setAuditList(audit))
    })
}

export const getAuditListAuth = (type) => (dispatch) => {
    auditAPI.getAudit("Auth").then(response => {
        console.log(response)
        let audit = response.data.map(arr => transformAuditAuth(arr))
        dispatch(setAuditListAuth(audit))
    })
}
