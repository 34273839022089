import React, {useEffect, useState} from "react";
import {useParams} from "react-router-dom"
import {useDispatch, useSelector} from "react-redux";
import {
    addAlbumToSection, addPlaylistToSection,
    delAlbumToSection,
    delPlaylistToSection,
    getSection,
    renameSection
} from "../redux/actions/sectionActions";
import {AlbumSectionList} from "../components/table/AlbumSectionList";
import {setOutsideSection} from "../redux/actions/albumActions";
import {AlbumSearch} from "../components/table/AlbumSearch";
import {Col, Radio, Row, Typography} from "antd";
import {PlaylistSearch} from "../components/search/PlaylistSearch";
import {getOutSideSectionPlaylist} from "../redux/actions/playlistActions";
const { Title, Paragraph } = Typography;

export const EditSectionPage = () => {

    const [findAlbums, setFindAlbums] = useState([]);
    const [value, setValue] = React.useState(1);
    let section = useSelector(state => state.section.section);
    let outsideSection = useSelector(state => state.album.outsideSection);
    let outsideSectionPlaylist = useSelector(state => state.playlist.outsideSection);

    const onChange = e => {
        setValue(e.target.value);
    };

    const dispatch = useDispatch()
    let idSection = useParams().id

    useEffect(()=>{
        dispatch(getSection(idSection))
    },[])

    const objectDelete = async (id, type) => {
        let isDel = window.confirm("удалить альбом из секции?");
        if (isDel) {
            if (type === 1) dispatch(delAlbumToSection({id, idSection}))
            else dispatch(delPlaylistToSection({id, idSection}))
        }
    };

    const deleteItem = (id, arr) => {
        const result = Array.from(arr);
        let index = result.find(s => s.idAlbum === id)
        result.splice(index, 1)
        return result
    };

    const objectAdd = async (id) => {
        dispatch(addAlbumToSection({id, idSection}))
        dispatch(setOutsideSection(deleteItem(id, outsideSection)));
        setFindAlbums(deleteItem(id, findAlbums))
    };

    const objectAddPlaylist = async (idPlaylist) => {
        dispatch(addPlaylistToSection({idPlaylist, idSection}))
        // dispatch(setOutsideSection(deleteItem(id, outsideSection)));
        // setFindAlbums(deleteItem(id, findAlbums))
    };

    const setEditableStr = (newName) => {
        dispatch(renameSection({idSection, newName}))
    }

    const onSearch = (value) => {
        setFindAlbums(outsideSection.filter((el) =>
            el.name.toLowerCase().indexOf(value.toLowerCase()) > -1
        ))
    };

    return (
        <Row gutter={[16, 16]}>
            <Col span={12}>
                <Radio.Group onChange={onChange} value={value} buttonStyle="solid">
                    <Radio.Button value={1}>Альбомы</Radio.Button>
                    <Radio.Button value={2}>Плейлисты</Radio.Button>
                </Radio.Group><br/><br/>
                {value === 1 && <>
                <Title level={3}>Найти альбомы</Title>
                <AlbumSearch findAlbums={findAlbums}
                             outsideSection={outsideSection}
                             objectAdd={objectAdd}
                             onSearch={onSearch}
                /></>}
                {value === 2 && <>
                    <Title level={3}>Найти плейлисты</Title>
                    <PlaylistSearch objectAdd={objectAddPlaylist} outsideSectionPlaylist={outsideSectionPlaylist}/>
                    </>}
            </Col>
            <Col span={12}>
                <Title level={2}><Paragraph editable={{ onChange: setEditableStr }}>{section.nameSection}</Paragraph></Title>
                <AlbumSectionList albums={section.cells}
                                  objectDelete={objectDelete}
                />
            </Col>
        </Row>
    )
}
