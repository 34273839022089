import {Button, Form, Modal} from "antd";
import React from "react";
import {CreateAlbumForm} from "../form/CreateAlbumForm";
import {useDispatch} from "react-redux";
import {createNewAlbum} from "../../redux/actions/albumActions";

export const CreateAlbumModal = ({setActiveKey, hideModal, visible, albumType}) => {
    const dispatch = useDispatch();
    const [form] = Form.useForm();

    const cancel = () => {
        hideModal();
        form.resetFields();
    }

    const onCheck = async () => {
        try {
            const values = await form.validateFields();
            dispatch(createNewAlbum(values));
            setActiveKey("2")
            cancel();
        } catch (errorInfo) {
            console.log('Failed:', errorInfo);
        }
    };

    return (
            <Modal
                visible={visible}
                title="Создать альбом"
                onOk={onCheck}
                onCancel={cancel}
                footer={[
                    <Button key="back" onClick={cancel}>
                        Отмена
                    </Button>,
                    <Button key="submit" type="primary" htmlType="submit"
                        // loading={loading}
                            onClick={onCheck}>
                        Создать
                    </Button>,
                ]}
            >
                <CreateAlbumForm form={form} albumType={albumType}/>
            </Modal>
    )
}
