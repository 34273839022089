import React from "react";
import {Form, Input, Button, Card} from 'antd';
import {UserOutlined, LockOutlined} from '@ant-design/icons';
import {useDispatch} from "react-redux";
import {auth} from "../redux/actions/authActions";
// import {auth} from "../redux/actions";


export const AuthPage = () => {
    const dispatch = useDispatch();
    const [form] = Form.useForm();

    const onFinish = data => {
        dispatch(auth(data));
        form.resetFields();
    };

    return (
        <Card bordered={true} style={{width: 300, margin: "100px auto 10px"}}>
            <Form
                form={form}
                name="normal_login"
                className="login-form"
                initialValues={{
                    remember: true,
                }}
                onFinish={onFinish}
            >
                <Form.Item
                    name="login"
                    rules={[
                        {
                            required: true,
                            message: 'Пожалуйста, введите ваш логин!',
                        },
                    ]}
                >
                    <Input prefix={<UserOutlined className="site-form-item-icon"/>} placeholder="Логин"/>
                </Form.Item>
                <Form.Item
                    name="password"
                    rules={[
                        {
                            required: true,
                            message: 'Пожалуйста, введите ваш пароль!',
                        },
                    ]}
                >
                    <Input
                        prefix={<LockOutlined className="site-form-item-icon"/>}
                        type="password"
                        placeholder="Пароль"
                    />
                </Form.Item>
                <Form.Item>
                    <Button type="primary" htmlType="submit" className="login-form-button">
                        Войти
                    </Button>
                </Form.Item>
            </Form>
        </Card>
    )
}
