import {Button, Form, Modal} from "antd";
import React from "react";
import {CreateAlbumForm} from "../form/CreateAlbumForm";
import {useDispatch} from "react-redux";
import {createNewAlbum, renameAlbum} from "../../redux/actions/albumActions";
import {EditAlbumForm} from "../form/EditAlbumForm";

export const EditAlbumModal = ({
                                   visible,
                                   setVisible,
                                   albumData

                               }) => {

    const dispatch = useDispatch()
    const [form] = Form.useForm();
    const cancel = () => {
        setVisible(false);
        form.resetFields();
    }

    const onFinish = values => {
        console.log('Finish:', values);
        dispatch(renameAlbum({
            idAlbum: albumData.idAlbum,
            newNameAlbum: values.newNameAlbum
        }))
        form.resetFields();
    };

    const onCheck = async () => {
        try {
            const values = await form.validateFields();
            console.log('Success:', values);
            dispatch(createNewAlbum(values))
            cancel();
        } catch (errorInfo) {
            console.log('Failed:', errorInfo);
        }
    };
    return (
        <Modal
            visible={visible}
            title="Редактировать альбом"
            onOk={onCheck}
            onCancel={cancel}
            footer={[
                <Button key="back"
                        onClick={cancel}
                >
                    Отмена
                </Button>
            ]}
        >
            <EditAlbumForm form={form}
                           albumData={albumData}
                           onFinish={onFinish}
            />
        </Modal>
    )
}
