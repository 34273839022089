import React, {useCallback, useContext, useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {Table} from 'antd';
import {getAuditList} from "../../redux/actions/auditActions";

export const AuditTable = ({type}) => {
    const { Column } = Table;
    const dispatch = useDispatch()
    let auditArr = useSelector(state => state.audit.auditList)

    useEffect(() => {
        dispatch(getAuditList(type));
    }, [type])

    return(
        <Table dataSource={auditArr}>
            <Column title="#" dataIndex="number" key="idAuditTrack" />
            <Column title="Статус" dataIndex="isSuccessful" key="isSuccessful" />
            <Column title="Время" dataIndex="timeOperation" key="timeOperation" />
            <Column title="Логин" dataIndex="login" key="executorLogin" />
            <Column title="Операция" dataIndex="action" key="action" />
            <Column title="Название" dataIndex="name" key="nameTrack" />
            <Column title="Инфо" dataIndex="oldValue" key="oldValue" />
            <Column title="ID" dataIndex="id" key="idTrack" />
        </Table>
    )
}
