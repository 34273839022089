import {SET_PAGE, SET_PAGE_LIST, SET_SECTION_LIST} from "../types";

let initialState = {
    pageList: [],
    page: [],
};

const sectionReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_PAGE_LIST:
            return {...state, pageList: action.payload}
        case SET_PAGE:
            return {...state, page: action.payload}
        default:
            return state;
    }
};

export default sectionReducer;
