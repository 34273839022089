import React from 'react';
import ReactDOM from 'react-dom';
import {BrowserRouter} from "react-router-dom";
import * as serviceWorker from './serviceWorker';
import {Router} from "./router";
import store from "./redux/store";

import 'antd/dist/antd.css';
import './index.css';
import {Provider} from "react-redux";

ReactDOM.render(
        <Provider store={store}>
            <BrowserRouter basename="/">
                <Router />
            </BrowserRouter>
        </Provider>,
    document.getElementById('root')
);

serviceWorker.unregister();

// React.StrictMode
