import React, {useState} from "react";
import {Typography} from "antd";
import {useDispatch} from "react-redux";
import {renameUploadTrack} from "../../redux/actions/albumActions";

const {Paragraph} = Typography;

export const NameRename = ({name, id}) => {
    const dispatch = useDispatch()
    const onChange = str => {
        console.log('Content change:', id, str);
        dispatch(renameUploadTrack({name: str, id}))
    };

    return (
        <Paragraph editable={{onChange: onChange}}>{name}</Paragraph>
    )
}
