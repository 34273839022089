import {propsAPI} from "../../api/API";
import {
    GET_AVAILABLE_PROPS,
    GET_GROUP_PROPS,
    SET_AVAILABLE_PROPS,
    SET_GROUP_PROPS,
    SET_PROPS_LIST,
    CREATE_PROPS_TRACK, UPDATE_PROPS_TRACK, DELETE_PROPS_TRACK, ADD_PROPS, GET_PROPS_LIST,
} from "../types";

export const setPropsList = (payload) => ({type: SET_PROPS_LIST, payload});
export const getPropsList = (payload) => ({type: GET_PROPS_LIST, payload});
export const getGroupList = (payload) => ({type: GET_GROUP_PROPS, payload});
export const setGroupList = (payload) => ({type: SET_GROUP_PROPS, payload});
export const getAvailableList = (payload) => ({type: GET_AVAILABLE_PROPS, payload});
export const setAvailableList = (payload) => ({type: SET_AVAILABLE_PROPS, payload});
export const createPropsTrack = (payload) => ({type: CREATE_PROPS_TRACK, payload});
export const updatePropsTrack = (payload) => ({type: UPDATE_PROPS_TRACK, payload});
export const deletePropsTrack = (payload) => ({type: DELETE_PROPS_TRACK, payload});
export const createNewProps = (payload) => ({type: ADD_PROPS, payload});

export const renameProps = (object) => (dispatch) => {
    propsAPI.rename(object).then(response => {
        console.log(response)
        dispatch(getPropsList())
    })
}
