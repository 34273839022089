import {Button, Form, Modal} from "antd";
import React from "react";
import {CreateAlbumForm} from "../form/CreateAlbumForm";
import {useDispatch} from "react-redux";
import {createNewAlbum, renameAlbum, renameTrack} from "../../redux/actions/albumActions";
import {EditAlbumForm} from "../form/EditAlbumForm";
import {EditTrackForm} from "../form/EditTrackForm";

export const EditTrackModal = ({
                                   visible,
                                   setVisible,
                                   editTrack,
                                   idAlbum

                               }) => {

    const dispatch = useDispatch()
    const [form] = Form.useForm();
    const cancel = () => {
        setVisible(false);
        form.resetFields();
    }

    const onFinish = values => {
        dispatch(renameTrack({
            idTrack: editTrack.id,
            newNameTrack: values.newNameTrack
        }, idAlbum))
        cancel();
    };


    return (
        <Modal
            visible={visible}
            title="Редактировать трек"
            onCancel={cancel}
            footer={[
                <Button key="back" onClick={cancel}>
                    Отмена
                </Button>
            ]}
        >
            <EditTrackForm form={form}
                           name={editTrack.name}
                           onFinish={onFinish}
            />
        </Modal>
    )
}
