import {SET_QUEUE, SET_ID_QUEUE} from "../types";

let initialState = {
    queue: [],
    idQueue: null
};

const queueReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_QUEUE:
            return {...state, queue: action.payload}
        case SET_ID_QUEUE:
            return {...state, idQueue: action.payload}
        default:
            return state;
    }
};

export default queueReducer;
