import {userAPI} from "../../api/API";
import {SET_DATA_USER, SET_USER_LIST, SET_USER_TYPE} from "../types";
import {setLoadingFalse, setLoadingTrue} from "./infoActions";
import {transformType, transformUserList} from "../../utils/transformAPI";
import {onMessage} from "../../utils/onMessage";

export const setDataUser = (payload) => ({type: SET_DATA_USER, payload});
export const setUserList = (payload) => ({type: SET_USER_LIST, payload});
export const setUserType = (payload) => ({type: SET_USER_TYPE, payload});

export const getDataUser = (id) => (dispatch) => {
    userAPI.getUser(id).then(response => {
        dispatch(setDataUser(response.data))
    })
}

export const getUserList = () => (dispatch) => {
    dispatch(setLoadingTrue)
    userAPI.getUserList().then(response => {
        console.log(transformUserList(response.data))
        dispatch(setUserList(transformUserList(response.data)))
        dispatch(setLoadingFalse)
    })
}

export const getUserType = () => (dispatch) => {
    userAPI.type()
        .then(response => {
            let type = response.data.map(arr => transformType(arr))
            dispatch(setUserType(type))
        })
}

export const createNewUser = (object) => (dispatch) => {
    userAPI.createNewUser(object).then(response => {
            onMessage(response)
            dispatch(getUserList())
        }
    )
}
