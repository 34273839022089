import {
    ADD_NEW_TRACKS,
    DEL_NEW_TRACK,
    RENAME_UPLOAD_TRACK, RESET_NUMBER_LOADED_ITEMS,
    SET_ALBUM_LIST, SET_ALBUM_LIST_UNCHECKED, SET_CLEAR_TRACKS,
    SET_DATA_ALBUM, SET_DEL_NEW_TRACK, SET_FIND_TRACKS,
    SET_LAST_ORDER,
    SET_NEW_TRACKS, SET_NUMBER_LOADED_ITEMS, SET_OUTSIDE_SECTION, SET_PROPS_TRACK_LIST,
    SET_TRACK_ALBUM,
    UPLOAD_WINDOW_OPEN
} from "../types";

let initialState = {
    albumType: {
        1: "музыка",
        2: "проповеди",
        3: "аудиокниги",
        4: "конференции",
        5: "аудиобиблия"
    },
    albumData: [],
    albumTracks: [],
    albumPropsTracks: [],
    albumList: [],
    albumListUnchecked: [],
    newTracks: [],
    lastOrder: '',
    uploadWindowOpen: false,
    numberLoaderItems: 0,
    findTrack: [],
    outsideSection: []
};

const albumReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_DATA_ALBUM:
            return {...state, albumData: {...action.payload}}
        case SET_TRACK_ALBUM:
            return {...state, albumTracks: action.payload}
        case SET_PROPS_TRACK_LIST:
            return {...state, albumPropsTracks: action.payload}
        case SET_ALBUM_LIST:
            return {...state, albumList: [...action.payload]}
        case SET_ALBUM_LIST_UNCHECKED:
            return {...state, albumListUnchecked: [...action.payload]}
        case SET_LAST_ORDER:
            return {...state, lastOrder: action.payload}
        case SET_NEW_TRACKS:
            return {...state, newTracks: action.payload}
        case ADD_NEW_TRACKS:
            return {...state, newTracks: [...state.newTracks, action.payload]}
        case SET_DEL_NEW_TRACK:
            return {...state, newTracks: [...action.payload]}
        case SET_CLEAR_TRACKS:
            return {...state, newTracks: []}
        case UPLOAD_WINDOW_OPEN:
            return {...state, uploadWindowOpen: action.payload}
        case SET_NUMBER_LOADED_ITEMS:
            return {...state, numberLoaderItems: action.payload}
        case RESET_NUMBER_LOADED_ITEMS:
            return {...state, numberLoaderItems: 0}
        case SET_FIND_TRACKS:
            return {...state, findTrack: action.payload}
        case SET_OUTSIDE_SECTION:
            return {...state, outsideSection: action.payload}
        default:
            return state;
    }
};

export default albumReducer;
