import React, {Component, useEffect, useState} from "react";
import ReactDOM from "react-dom";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import {DropSectionItem} from "./DropSectionItem";
import {Col, Row} from "antd";
import {useDispatch} from "react-redux";
import {updateOrderSectionToPage} from "../../redux/actions/pageActions";

// a little function to help us with reordering the result
const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
};

const grid = 4;

const getItemStyle = (isDragging, draggableStyle) => ({
    // some basic styles to make the items look a bit nicer
    userSelect: "none",
    padding: grid * 2,
    margin: `0 0 ${grid}px 0`,
    // background: '#fafafa',
    borderBottom: '1px solid #F0F0F0',

    // change background colour if dragging
    // background: isDragging ? "lightgreen" : "#fff",
    background: isDragging ? "#f6f6f6" : "#fff",

    // styles we need to apply on draggables
    ...draggableStyle
});

const getListStyle = isDraggingOver => ({
    // background: isDraggingOver ? "lightblue" : "lightgrey",
    background: "#fff",
    padding: grid,
    width: '100%'
});

export const DropSectionPage = ({pageList, sectionDelete}) => {
    pageList.sort((a, b)=>(a.content.order - b.content.order));

    const [state, setState] = useState({items: pageList});
    const dispatch = useDispatch()
    console.log(state)

    useEffect(() => setState({items: pageList}), [pageList])

    const onDragEnd = (result) => {
        if (!result.destination) return;

        const items = reorder(
            state.items,
            result.source.index,
            result.destination.index
        );

        setState({items})
        dispatch(updateOrderSectionToPage(items))
    };

        return (
            <DragDropContext onDragEnd={onDragEnd}>
                <Droppable droppableId="droppable">
                    {(provided, snapshot) => (
                        <div
                            {...provided.droppableProps}
                            ref={provided.innerRef}
                            style={getListStyle(snapshot.isDraggingOver)}
                        >
                            {state.items.map((item, index) => (
                                <Draggable key={item.id} draggableId={item.id} index={index}>
                                    {(provided, snapshot) => (
                                        <div
                                            ref={provided.innerRef}
                                            {...provided.draggableProps}
                                            {...provided.dragHandleProps}
                                            style={getItemStyle(
                                                snapshot.isDragging,
                                                provided.draggableProps.style
                                            )}
                                        >
                                            <DropSectionItem content={item.content} sectionDelete={sectionDelete}/>
                                        </div>
                                    )}
                                </Draggable>
                            ))}
                            {provided.placeholder}
                        </div>
                    )}
                </Droppable>
            </DragDropContext>
        );
    }
