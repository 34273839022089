import React, {useEffect, useState} from "react";
import {useParams} from "react-router-dom"
import {useDispatch, useSelector} from 'react-redux'
import {getDataAlbum, renameUploadTrack, setAlbumStatus, uploadWindowOpen} from "../redux/actions/albumActions";
import {UploadTrackModal} from "../components/modal/UploadTracksModal";
import {EditAlbumModal} from "../components/modal/EditAlbumModal";
import {Card, PageHeader, Steps} from "antd";
import {EditTrackModal} from "../components/modal/EditTrackModal";
import {ToggleEditAlbum} from "../components/album/ToggleEditAlbum";
import {UploadBar} from "../components/trackUploadManager/UploadBar";

const EditAlbumPage = props => {
    const dispatch = useDispatch()
    let idAlbum = useParams().id

    let albumData = useSelector(state => state.album.albumData);
    let albumTracks = useSelector(state => state.album.albumTracks);
    let albumType = useSelector(state => state.album.albumType)
    let newTracks = useSelector(state => state.album.newTracks)
    let uploadWindowStatus = useSelector(state => state.album.uploadWindowOpen)
    let numberLoaderItems = useSelector(state => state.album.numberLoaderItems)

    const [editAlbumVisible, setEditAlbumVisible] = useState(false)
    const [editTrackVisible, setEditTrackVisible] = useState(false)
    const [editTrack, setEditTrack] = useState({})
    const [loading, setLoading] = useState(true);
    const [toggle, setToggle] = useState(false);
    const [status, setStatus] = useState(albumData.isChecked)

    useEffect(() => {
        setStatus(albumData.isChecked)
    }, [albumData])

    useEffect(() => {
        dispatch(getDataAlbum(idAlbum))
    }, [idAlbum]);

    useEffect(() => {
        if (newTracks.length < 1) {
            setLoading(true)
        } else {
            setLoading(false)
        }
    }, [newTracks])

    const trackSave = (event) => {
        const index = event.currentTarget.id;
        const name = event.currentTarget.value;
        dispatch(renameUploadTrack({index, name}))
    }

    const uploadWindow = (bool) => {
        dispatch(uploadWindowOpen(bool))
    }

    const showModalEdit = () => {
        setEditAlbumVisible(true)
    };

    const EditItem = (id, name) => {
        setEditTrackVisible(true)
        setEditTrack({id: id, name: name})
    }

    function onChangeChecked(checked) {
        setStatus(checked)
        dispatch(setAlbumStatus({idAlbum, checked}))
    }

    const toggleChange = () => {
        setToggle(!toggle)
    };

    return (
        <>
            <PageHeader
                ghost={false}
                onBack={() => window.history.back()}
                title={albumData.name}
                extra={<UploadBar showModal={uploadWindow}
                                  loading={loading}
                                  toggleChange={toggleChange}
                                  showModalEdit={showModalEdit}
                />}
            >
                <ToggleEditAlbum toggle={toggle}
                                 albumTracks={albumTracks}
                                 EditItem={EditItem}
                                 idAlbum={idAlbum}
                                 albumData={albumData}
                                 albumType={albumType}
                                 status={status}
                                 onChangeChecked={onChangeChecked}
                />
            </PageHeader>

            {/*модальные окошки*/}
            <UploadTrackModal visible={uploadWindowStatus}
                              setViseble={uploadWindow}
                              newTracks={newTracks}
                              trackSave={trackSave}
                              loading={loading}
                              numberLoaderItems={numberLoaderItems}
                              albumData={albumData}
            />


            <EditAlbumModal visible={editAlbumVisible}
                            setVisible={setEditAlbumVisible}
                            albumData={albumData}

            />

            <EditTrackModal visible={editTrackVisible}
                            setVisible={setEditTrackVisible}
                            editTrack={editTrack}
                            idAlbum={idAlbum}
            />

        </>
    )
}

export default EditAlbumPage
