import {useCallback, useEffect, useState} from "react";
let song = new Audio()

export const usePlay = () => {
    const [link, setLink] = useState(null)
    const [isPlaying, setIsPlaying] = useState(false)
    const [userId, setUserId] = useState(null)
    song.src = link;

    const onLoadedData = (e) => {
        // console.log(song.readyState)
        if (song.readyState >= 2) {
            song.play();
        }
    }

    const onPlay = (e) => {
        // console.log(e)
        // this.timer = requestInterval(this.update.bind(this), 50);
        setIsPlaying(true);
    }

    const onPause = () => {
        // console.log(this.timer)
        // clearRequestInterval(this.timer);
        setIsPlaying(false);
    }

    const onEnded = (callback) => {
        callback()
    }

    song.addEventListener('loadeddata', onLoadedData.bind(this));
    song.addEventListener('play', onPlay.bind(this));
    song.addEventListener('pause', onPause.bind(this));
    song.addEventListener('ended', onEnded.bind(this));

    return {setLink, isPlaying, onLoadedData, onEnded}
}
