import DropUploadFile from "../drop/DropUploadFile";
import {Manager} from "./TrackManager";
import {Col, Row, Select} from "antd";
import React, {useEffect} from "react";
import {PropertiesManager} from "../trackPropsManager/PropertiesManager";
import {AlbumCover} from "./AlbumCover";
import {useDispatch, useSelector} from "react-redux";
import {deleteTrack, getPropsTracksList, updatePropsTracksList} from "../../redux/actions/albumActions";
import {getPropsList} from "../../redux/actions/propsActions";

export const ToggleEditAlbum = (props) => {

    const {
        toggle,
        albumTracks,
        EditItem,
        idAlbum,
        albumData,
        albumType,
        status,
        onChangeChecked
    } = props;

    const dispatch = useDispatch()
    let groupList = useSelector(state => state.props.groupList);
    let albumPropsTracks = useSelector(state => state.album.albumPropsTracks);
    let available = useSelector(state => state.props.available);
    let propsList = useSelector(state => state.props.list);
    const del = (id) => dispatch(deleteTrack({id, idAlbum}));

    useEffect(() => {
        dispatch(getPropsTracksList(idAlbum))
    }, [toggle]);

    useEffect(()=>{
        dispatch(getPropsList())
    },[]);

    if (toggle) {
        return (
            <>
                <Row>
                    <Col span={8}></Col>
                    <Col span={4}></Col>
                    <Col span={4}></Col>
                    <Col span={4}></Col>
                    <Col span={4}></Col>
                </Row>
                <PropertiesManager idTypeAudio={albumData.idTypeAudio}
                                   idAlbum={idAlbum}
                                   groupList={groupList}
                                   albumPropsTracks={albumPropsTracks}
                                   available={available}
                                   propsList={propsList}
                />
            </>
        )
    } else {
        return (
            <Row gutter={16}>
                <Col span={6}>
                    <AlbumCover albumData={albumData}
                                albumType={albumType}
                                status={status}
                                onChangeChecked={onChangeChecked}/>
                </Col>

                <Col span={18}>
                <DropUploadFile/><br/>
                <Manager
                    tracks={albumTracks}
                    EditItem={EditItem}
                    idAlbum={idAlbum}
                    type={'album'}
                    del={del}
                />
                </Col>
            </Row>
        )
    }
}
