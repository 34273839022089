import {
    ADD_SECTION_TO_PAGE,
    DEL_SECTION_TO_PAGE,
    GET_PAGE,
    GET_PAGE_LIST,
    SET_PAGE,
    SET_PAGE_LIST,
    UPDATE_ORDER_SECTION_TO_PAGE
} from "../types";

export const getPageList = (payload) => ({type: GET_PAGE_LIST, payload});
export const setPageList = (payload) => ({type: SET_PAGE_LIST, payload});
export const getPage = (payload) => ({type: GET_PAGE, payload});
export const setPage = (payload) => ({type: SET_PAGE, payload});
export const addSectionToPage = (payload) => ({type: ADD_SECTION_TO_PAGE, payload});
export const delSectionToPage = (payload) => ({type: DEL_SECTION_TO_PAGE, payload});
export const updateOrderSectionToPage = (payload) => ({type: UPDATE_ORDER_SECTION_TO_PAGE, payload});

