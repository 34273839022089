export const SET_AUTH_TRUE = "AUTH/SET_AUTH_TRUE"
export const SET_AUTH_FALSE = "AUTH/SET_AUTH_FALSE"
export const SET_READY_FALSE = "AUTH/SET_READY_FALSE"
export const SET_READY_TRUE = "AUTH/SET_READY_TRUE"

export const GET_DATA_ALBUM = "ALBUM/GET_DATA_ALBUM";
export const CREATE_NEW_ALBUM = "ALBUM/CREATE_NEW_ALBUM";
export const DELETE_ALBUM = "ALBUM/DELETE_ALBUM";
export const SET_DATA_ALBUM = "ALBUM/SET_DATA_ALBUM";
export const SET_TRACK_ALBUM = "ALBUM/SET_TRACK_ALBUM";
export const GET_ALBUM_LIST = "ALBUM/GET_ALBUM_LIST";
export const SET_ALBUM_LIST = "ALBUM/SET_ALBUM_LIST";
export const GET_ALBUM_LIST_UNCHECKED = "ALBUM/GET_ALBUM_LIST_UNCHECKED";
export const SET_ALBUM_LIST_UNCHECKED = "ALBUM/SET_ALBUM_LIST_UNCHECKED";
export const SET_ALBUM_STATUS = "ALBUM/SET_ALBUM_STATUS";
export const UPDATE_COVER_ALBUM = "ALBUM/UPDATE_COVER_ALBUM";
export const RENAME_ALBUM = "ALBUM/RENAME_ALBUM";
export const GET_OUTSIDE_SECTION = "ALBUM/GET_OUTSIDE_SECTION";
export const SET_OUTSIDE_SECTION = "ALBUM/SET_OUTSIDE_SECTION";

export const SET_NEW_TRACKS = "ALBUM/SET_NEW_TRACK";
export const ADD_NEW_TRACKS = "ALBUM/ADD_NEW_TRACK";
export const DEL_NEW_TRACK = "ALBUM/DEL_NEW_TRACK";
export const SET_DEL_NEW_TRACK = "ALBUM/SET_DEL_NEW_TRACK";
export const UPLOAD_NEW_TRACKS = "ALBUM/UPLOAD_NEW_TRACKS";
export const SAVE_NEW_TRACKS = "ALBUM/SAVE_NEW_TRACKS";
export const DELETE_TRACK = "ALBUM/DELETE_TRACK";
export const UPDATE_ORDERS_TRACK_ALBUM = "ALBUM/UPDATE_ORDERS_TRACK_ALBUM";
export const SET_CLEAR_TRACKS = "ALBUM/SET_CLEAR_TRACKS";
export const SET_LAST_ORDER = "ALBUM/SET_LAST_ORDER";
export const RENAME_UPLOAD_TRACK = "ALBUM/RENAME_UPLOAD_TRACK";
export const RENAME_TRACK = "ALBUM/RENAME_TRACK";


export const UPLOAD_WINDOW_OPEN = "ALBUM/UPLOAD_WINDOW_OPEN";
export const SET_NUMBER_LOADED_ITEMS = "ALBUM/SET_NUMBER_LOADED_ITEMS";
export const RESET_NUMBER_LOADED_ITEMS = "ALBUM/RESET_NUMBER_LOADED_ITEMS";

export const SET_DATA_USER = "USER/SET_DATA_USER";
export const SET_USER_LIST = "USER/SET_USER_LIST";
export const SET_USER_TYPE = "USER/SET_USER_TYPE";

export const SET_AUDIT_LIST = "AUDIT/SET_AUDIT_LIST";
export const SET_AUDIT_LIST_AUTH = "AUDIT/SET_AUDIT_LIST_AUTH";

export const SET_LOADING_TRUE = "AUDIT/SET_LOADING_TRUE";
export const SET_LOADING_FALSE = "AUDIT/SET_LOADING_FALSE";

export const SET_MESSAGE = "AUDIT/SET_MESSAGE";
export const SET_ERROR_MESSAGE = "AUDIT/SET_ERROR_MESSAGE";

export const GET_PROPS_LIST = "PROPS/GET_PROPS_LIST";
export const SET_PROPS_LIST = "PROPS/SET_PROPS_LIST";
export const GET_GROUP_PROPS = "PROPS/GET_GROUP_PROPS";
export const SET_GROUP_PROPS = "PROPS/SET_GROUP_PROPS";
export const GET_AVAILABLE_PROPS = "PROPS/GET_AVAILABLE_PROPS";
export const SET_AVAILABLE_PROPS = "PROPS/SET_AVAILABLE_PROPS";
export const CREATE_PROPS_TRACK = "PROPS/CREATE_PROPS_TRACK";
export const UPDATE_PROPS_TRACK = "PROPS/UPDATE_PROPS_TRACK";
export const DELETE_PROPS_TRACK = "PROPS/DELETE_PROPS_TRACK";
export const ADD_PROPS = "PROPS/ADD_PROPS";

export const UPDATE_PROPS_TRACK_LIST = "TRACK/UPDATE_PROPS_TRACK_LIST";
export const GET_PROPS_TRACK_LIST = "TRACK/GET_PROPS_TRACK_LIST";
export const SET_PROPS_TRACK_LIST = "TRACK/SET_PROPS_TRACK_LIST";

export const FIND_TRACKS = "TRACK/FIND_TRACKS";
export const SET_FIND_TRACKS = "TRACK/SET_FIND_TRACKS";

export const GET_PERFORMER_LIST = "PERFORMER/GET_PERFORMER_LIST";
export const SET_PERFORMER_LIST = "PERFORMER/SET_PERFORMER_LIST";
export const RENAME_PERFORMER = "PERFORMER/RENAME_PERFORMER";
export const CREATE_PERFORMER = "PERFORMER/CREATE_PERFORMER";

export const GET_QUEUE = "QUEUE/GET_QUEUE";
export const SET_QUEUE = "QUEUE/SET_QUEUE";
export const SET_ID_QUEUE = "QUEUE/SET_ID_QUEUE";

export const CREATE_PLAYLIST = "PLAYLIST/CREATE_PLAYLIST";
export const RENAME_PLAYLIST = "PLAYLIST/RENAME_PLAYLIST";
export const UPLOAD_COVER_PLAYLIST = "PLAYLIST/UPLOAD_COVER_PLAYLIST";
export const GET_GENERAL_LIST = "PLAYLIST/GET_GENERAL_LIST";
export const SET_GENERAL_LIST = "PLAYLIST/SET_GENERAL_LIST";
export const GET_PLAYLIST = "PLAYLIST/GET_PLAYLIST";
export const SET_PLAYLIST = "PLAYLIST/SET_PLAYLIST";
export const SET_PLAYLIST_TRACKS = "PLAYLIST/SET_PLAYLIST_TRACKS";
export const ADD_TRACK_TO_PLAYLIST = "PLAYLIST/ADD_TRACK_TO_PLAYLIST";
export const DELETE_TRACK_PLAYLIST = "PLAYLIST/DELETE_TRACK_PLAYLIST";
export const DELETE_PLAYLIST = "PLAYLIST/DELETE_PLAYLIST";
export const UPDATE_ORDERS_PLAYLIST = "PLAYLIST/UPDATE_ORDERS_PLAYLIST";
export const GET_OUTSIDE_SECTION_PLAYLIST = "PLAYLIST/GET_OUTSIDE_SECTION_PLAYLIST";
export const SET_OUTSIDE_SECTION_PLAYLIST = "PLAYLIST/SET_OUTSIDE_SECTION_PLAYLIST";
export const UPDATE_PUBLIC_STATUS_PLAYLIST = "PLAYLIST/UPDATE_PUBLIC_STATUS_PLAYLIST";

export const UPDATE_ORDERS = "TRACKS/UPDATE_ORDERS";

export const GET_SECTION_LIST = "SECTION/GET_SECTION_LIST";
export const SET_SECTION_LIST = "SECTION/SET_SECTION_LIST";
export const GET_SECTION = "SECTION/GET_SECTION";
export const SET_SECTION = "SECTION/SET_SECTION";
export const CREATE_SECTION = "SECTION/CREATE_SECTION";
export const CREATE_SECTION_TO_PAGE = "SECTION/CREATE_SECTION_TO_PAGE";
export const RENAME_SECTION = "SECTION/RENAME_SECTION";
export const ADD_ALBUM_TO_SECTION = "SECTION/ADD_ALBUM_TO_SECTION";
export const ADD_PLAYLIST_TO_SECTION = "SECTION/ADD_PLAYLIST_TO_SECTION";
export const DEL_SECTION = "SECTION/DEL_SECTION";
export const DEL_ALBUM_TO_SECTION = "SECTION/DEL_ALBUM_TO_SECTION";
export const DEL_PLAYLIST_TO_SECTION = "SECTION/DEL_PLAYLIST_TO_SECTION";

export const GET_PAGE_LIST = "SECTION/GET_PAGE_LIST";
export const SET_PAGE_LIST = "SECTION/SET_PAGE_LIST";
export const GET_PAGE = "SECTION/GET_PAGE";
export const SET_PAGE = "SECTION/SET_PAGE";
export const ADD_SECTION_TO_PAGE = "SECTION/ADD_SECTION_TO_PAGE";
export const DEL_SECTION_TO_PAGE = "SECTION/DEL_SECTION_TO_PAGE";
export const UPDATE_ORDER_SECTION_TO_PAGE = "SECTION/UPDATE_ORDER_SECTION_TO_PAGE";

