import React from 'react';
import {Layout} from 'antd';
import {Header} from "./components/nav/Header";
import {Sidebar} from "./components/nav/Sidebar";
import {Content} from "./components/content/Content";
import {usePlayer} from "./hooks/usePlayer";
import {Audiocontext} from "./context/audioContext";

function App() {
    const {newAudio} = usePlayer();

    return (
        <Audiocontext.Provider value={newAudio}>
            <Layout>
                <Header/>
                <Layout>
                    <Sidebar/>
                    <Layout style={{padding: '0 24px 24px'}}>
                        <Content/>
                    </Layout>
                </Layout>
            </Layout>
        </Audiocontext.Provider>
    );
}

export default App;
