import {
    ADD_NEW_TRACKS, CREATE_NEW_ALBUM,
    DEL_NEW_TRACK,
    DELETE_ALBUM, DELETE_TRACK,
    FIND_TRACKS,
    GET_ALBUM_LIST,
    GET_ALBUM_LIST_UNCHECKED,
    GET_DATA_ALBUM, GET_OUTSIDE_SECTION,
    GET_PROPS_TRACK_LIST, RENAME_ALBUM, RENAME_TRACK,
    RENAME_UPLOAD_TRACK, RESET_NUMBER_LOADED_ITEMS,
    SAVE_NEW_TRACKS,
    SET_ALBUM_LIST,
    SET_ALBUM_LIST_UNCHECKED,
    SET_ALBUM_STATUS,
    SET_CLEAR_TRACKS,
    SET_DATA_ALBUM, SET_DEL_NEW_TRACK,
    SET_FIND_TRACKS,
    SET_LAST_ORDER,
    SET_NEW_TRACKS, SET_NUMBER_LOADED_ITEMS, SET_OUTSIDE_SECTION,
    SET_PROPS_TRACK_LIST,
    SET_TRACK_ALBUM, UPDATE_COVER_ALBUM, UPDATE_ORDERS, UPDATE_ORDERS_TRACK_ALBUM,
    UPDATE_PROPS_TRACK_LIST,
    UPLOAD_NEW_TRACKS,
    UPLOAD_WINDOW_OPEN
} from "../types";

export const createNewAlbum = (payload) => ({type: CREATE_NEW_ALBUM, payload})
export const setDataAlbum = (payload) => ({type: SET_DATA_ALBUM, payload});
export const setAlbumStatus = (payload) => ({type: SET_ALBUM_STATUS, payload});
export const setTracksAlbum = (payload) => ({type: SET_TRACK_ALBUM, payload});
export const setAlbumList = (payload) => ({type: SET_ALBUM_LIST, payload});
export const setAlbumListUnchecked = (payload) => ({type: SET_ALBUM_LIST_UNCHECKED, payload});
export const getAlbumListUnchecked = (payload) => ({type: GET_ALBUM_LIST_UNCHECKED, payload});
export const setLastOrder = (payload) => ({type: SET_LAST_ORDER, payload});
export const uploadWindowOpen = (payload) => ({type: UPLOAD_WINDOW_OPEN, payload});
export const setNumberLoaderItems = (payload) => ({type: SET_NUMBER_LOADED_ITEMS, payload});
export const resetNumberLoaderItems = () => ({type: RESET_NUMBER_LOADED_ITEMS});

export const updatePropsTracksList = (payload) => ({type: UPDATE_PROPS_TRACK_LIST, payload});

export const getPropsTracksList = (payload) => ({type: GET_PROPS_TRACK_LIST, payload});
export const setPropsTracksList = (payload) => ({type: SET_PROPS_TRACK_LIST, payload});


export const setNewTracks = (payload) => ({type: SET_NEW_TRACKS, payload});
export const addNewTracks = (payload) => ({type: ADD_NEW_TRACKS, payload});
export const delNewTracks = (payload) => ({type: DEL_NEW_TRACK, payload});
export const setDelNewTracks = (payload) => ({type: SET_DEL_NEW_TRACK, payload});
export const setClearTracks = (payload) => ({type: SET_CLEAR_TRACKS});

export const findTracks = (payload) => ({type: FIND_TRACKS, payload});
export const setFindTracks = (payload) => ({type: SET_FIND_TRACKS, payload});

export const uploadNewTracks = (payload) => ({type: UPLOAD_NEW_TRACKS, payload});

export const saveNewTracks = (data, albumData) => ({type: SAVE_NEW_TRACKS, payload: {data, albumData}})
export const getDataAlbum = (payload) => ({type: GET_DATA_ALBUM, payload})
export const getAlbumList = () => ({type: GET_ALBUM_LIST})

export const deleteAlbum = (payload) => ({type: DELETE_ALBUM, payload})
export const deleteTrack = (payload) => ({type: DELETE_TRACK, payload})
export const updateOrdersAlbum = (payload) => ({type: UPDATE_ORDERS_TRACK_ALBUM, payload})
export const renameAlbum = (payload) => ({type: RENAME_ALBUM, payload})
export const updateCoverAlbum = (payload) => ({type: UPDATE_COVER_ALBUM, payload})
export const renameTrack = (payload) => ({type: RENAME_TRACK, payload})
export const getOutsideSection = (payload) => ({type: GET_OUTSIDE_SECTION, payload})
export const setOutsideSection = (payload) => ({type: SET_OUTSIDE_SECTION, payload})


export const renameUploadTrack = ({id, name}) => ({type: RENAME_UPLOAD_TRACK, payload: {id, name}});

export const updateOrders = (payload) => ({type: UPDATE_ORDERS, payload})
