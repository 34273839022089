import React, {useEffect, useState} from 'react';
import 'antd/dist/antd.css';
import {Button, PageHeader, Select, Tabs} from 'antd';
import {useDispatch, useSelector} from "react-redux";
import {getGroupList, getPropsList, getTypeProps} from "../../redux/actions/propsActions";
import {PropertiesTable} from "../table/PropertiesTable";
import {CreatePropModal} from "../modal/CreatePropModal";
import {UploadBar} from "../trackUploadManager/UploadBar";
import {ToggleEditAlbum} from "../album/ToggleEditAlbum";

const {TabPane} = Tabs;
const {Option} = Select;

export const Properties = () => {
    const dispatch = useDispatch()
    let groupList = useSelector(state => state.props.groupList);
    let list = useSelector(state => state.props.list)
    const [active, setActive] = useState(1);
    const [visible, setVisible] = useState();

    const showModal = () => {
        setVisible(true)
    };

    const handleCancel = e => {
        setVisible(false)
    };

    useEffect(() => {
        dispatch(getGroupList())
        dispatch(getPropsList());
    }, [])

    const tabs = list.map((group) => {
        return (
            <TabPane tab={group.nameGroup} key={group.idGroupProp}>
                <PropertiesTable properties={group.properties}/>
            </TabPane>
        )
    })

    const selectTypes = groupList.map((group) => {
        return (
            <Option key={group.name}
                    value={group.idGroupProp}
            >{group.name}</Option>
        )
    })

    function callback(key){
        setActive(key)
        dispatch(getPropsList(key));
    }

    return (
        <>
            <PageHeader
                ghost={false}
                onBack={() => window.history.back()}
                title={"Свойства"}
                extra={<Button onClick={showModal}>Добавить новое свойство</Button>}
            >
                <Tabs defaultActiveKey={active} onChange={callback}>
                    {tabs}
                </Tabs>
            </PageHeader>


            <CreatePropModal visible={visible}
                             handleCancel={handleCancel}
                             selectTypes={selectTypes}
                             active={active}
            />
        </>
    )

}
