import React, {useEffect, useState} from "react";
import {Input, Space, Spin, Table, Tag} from "antd";
import {getOutsideSection} from "../../redux/actions/albumActions";
import {useDispatch, useSelector} from "react-redux";
import {getGeneralPlaylist, getOutSideSectionPlaylist} from "../../redux/actions/playlistActions";
const { Search } = Input;

export const PlaylistSearch = ({findAlbums, objectAdd, onSearch, outsideSectionPlaylist}) => {

    // const dispatch = useDispatch()
    // useEffect(()=>{dispatch(getOutSideSectionPlaylist({}))},[])
    // const outsideSectionPlaylist = useSelector(state => state.playlist.outsideSection);
    console.log(outsideSectionPlaylist)

    const columns = [
        {
            title: 'id',
            dataIndex: 'idPlaylist',
            key: 'idPlaylist',
        },
        {
            title: 'Название',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: '',
            key: 'action',
            render: (text, record) => (
                <Space size="middle">
                    <a onClick={() => objectAdd(record.idPlaylist, record.idTypeCell)} key="add">
                        добавить
                    </a>
                </Space>
            ),
        },
    ];

    return (
        <>
            {/*<Search placeholder="Введите название альбома" onSearch={onSearch} style={{minWidth: 300, marginBottom: 30}}/>*/}
            {outsideSectionPlaylist.length > 0 && <h3>Найдено: {outsideSectionPlaylist.length} </h3>}
            {outsideSectionPlaylist.length > 0 && <Table dataSource={outsideSectionPlaylist}
                   columns={columns}
                   pagination={{hideOnSinglePage: true}}
                   style={{marginBottom: 30}}
            />}
            {/*{generalList.length < 1 && <h3>Список всех доступных альбомов:</h3>}*/}
            {/*{generalList.length < 1 && <Table dataSource={generalList}*/}
            {/*        columns={columns}*/}
            {/*        pagination={{hideOnSinglePage: true}}*/}
            {/*        style={{marginBottom: 30}}*/}
            {/*/>}*/}
        </>
    )
}




