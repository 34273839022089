import React from "react";
import {Col, Row} from "antd";

const getItemStyle = {
    userSelect: "none",
    padding: 12,
    // margin: `0 0 4px 0`,
    borderBottom: '1px solid #F0F0F0',
    background: "#f6f6f6",
};

export const DropSectionTitle = ({content}) => {

return (
    <div className={'test'} style={getItemStyle}>
    <Row>
        <Col span={4}>ID</Col>
        <Col span={14}>Название секции</Col>
        <Col span={6}></Col>
    </Row>
    </div>
    );
};
