import React, {useEffect, useState} from 'react';
import ReactDOM from 'react-dom';
import 'antd/dist/antd.css';
import {Table, Input, InputNumber, Popconfirm, Form, Space} from 'antd';
import {EditableCell} from "./EditableCell";
import {useDispatch} from "react-redux";
import {renamePlaylist} from "../../redux/actions/playlistActions";
import {NavLink} from "react-router-dom";


export const PlaylistTable = ({playlists, rename}) => {
    const [form] = Form.useForm();
    const dispatch = useDispatch()
    const [data, setData] = useState(playlists);
    const [editingKey, setEditingKey] = useState('');

    useEffect(() => {
        setData(playlists)
    }, [playlists])

    const isEditing = record => record.key === editingKey;

    const edit = record => {
        form.setFieldsValue({
            name: '',
            ...record,
        });
        setEditingKey(record.key);
    };

    const cancel = () => {
        setEditingKey('');
    };

    const save = async key => {
        try {
            const row = await form.validateFields();
            console.log({idPlaylist: key, newName: row.name})
            rename({idPlaylist: key, newName: row.name});
            const newData = [...data];
            const index = newData.findIndex(item => key === item.key);

            if (index > -1) {
                const item = newData[index];
                newData.splice(index, 1, {...item, ...row});
                setData(newData);
                setEditingKey('');
            } else {
                newData.push(row);
                setData(newData);
                setEditingKey('');
            }
        } catch (errInfo) {
            console.log('Validate Failed:', errInfo);
        }
    };

    const columns = [
        {
            title: 'id',
            dataIndex: 'idPlaylist',
            key: 'idPlaylist',
            editable: false,
        },
        {
            title: 'Название',
            dataIndex: 'name',
            key: 'name',
            editable: true,
            render: (_, record) => {
                return (
                    <NavLink to={`/playlist/${record.idPlaylist}`}>{record.name}</NavLink>
                )}
        },
        {
            title: '',
            dataIndex: 'operation',
            render: (_, record) => {
                const editable = isEditing(record);
                return editable ? (
                    <span>
            <a
                onClick={() => save(record.key)}
                style={{marginRight: 8,}}
            >
              сохранить
            </a>
            <Popconfirm title="отменить?" onConfirm={cancel} okText={"да"} cancelText={"нет"}>
              <a>отменить</a>
            </Popconfirm>
          </span>
                ) : (
                    <a disabled={editingKey !== ''} onClick={() => edit(record)}>
                        изменить
                    </a>
                );
            },
        },
    ];
    const mergedColumns = columns.map(col => {
        if (!col.editable) {
            return col;
        }

        return {
            ...col,
            onCell: record => ({
                record,
                dataIndex: col.dataIndex,
                title: col.title,
                editing: isEditing(record),
            }),
        };
    });
    return (
        <Form form={form} component={false}>
            <Table
                components={{
                    body: {
                        cell: EditableCell,
                    },
                }}
                bordered
                dataSource={data}
                columns={mergedColumns}
                rowClassName="editable-row"
                pagination={{
                    onChange: cancel,
                }}
            />
        </Form>
    );
};

