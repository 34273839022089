export const convertData = (data) => {
        // return new Promise(function (resolve, reject) {
            let d = new Date(data);
            return formatDate(d);
        // });
}

function formatDate(date) {

    let dd = date.getDate();
    if (dd < 10) dd = '0' + dd;

    let mm = date.getMonth() + 1;
    if (mm < 10) mm = '0' + mm;

    let yy = date.getFullYear() % 100;
    if (yy < 10) yy = '0' + yy;

    let hor = date.getHours();
    if (hor < 10) hor = '0' + hor;

    let min = date.getMinutes();
    if (min < 10) min = '0' + min;

    let sec = date.getSeconds();
    if (sec < 10) sec = '0' + sec;

    console.log(dd + '.' + mm + '.' + yy + ' ' + hor + ':' + min + ':' + sec)
    return dd + '.' + mm + '.' + yy + ' ' + hor + ':' + min + ':' + sec;
}
