import React from "react";
import {CaretLeftOutlined, CaretRightOutlined, PlayCircleOutlined } from '@ant-design/icons';
import Icon from '@ant-design/icons';
import ReactJkMusicPlayer from "react-jinke-music-player";

export const Player = () => {
    const customLocale = {
        playModeText: {
            order: '',
            orderLoop: '',
            singleLoop: '',
            shufflePlay: ''
        },
        openText: '',
        closeText: '',
        notContentText: '',
        clickToPlayText: '',
        clickToPauseText: '',
        nextTrackText: '',
        previousTrackText: '',
        reloadText: '',
        volumeText: '',
        playListsText: '',
        toggleLyricText: '',
        toggleMiniModeText: '',
        destroyText: '',
        downloadText: '',
        lightThemeText: '',
        darkThemeText: '',
        switchThemeText: '',
        removeAudioListsText: '',
        controllerTitle: '',
        emptyLyricText: '',
        clickToDeleteText: (name) => ``,
        audioTitle: ''
        // audioTitle: (audioInfo) => ``
    }



    return (
        <div style={{display: 'flex', alignItems: 'center'}}>
            {/*<ReactJkMusicPlayer locale={customLocale}/>*/}
            {/*<ReactJkMusicPlayer />*/}
        </div>
    )
}

// export const Player = () => {
//     return (
//         <div style={{display: 'flex', alignItems: 'center'}}>
//             {/*<FullControlClass />*/}
//             {/*<CaretLeftOutlined style={{fontSize: 15}}/>*/}
//             {/*<PlayCircleOutlined style={{fontSize: 22}}/>*/}
//             {/*/!*<CaretRightOutlined style={{fontSize: 25}}/>*!/*/}
//             {/*<CaretRightOutlined style={{fontSize: 15}}/>*/}
//             {/*/!*<HeartIcon style={{ color: 'hotpink' }} />*!/*/}
//         </div>
//     )
// }

