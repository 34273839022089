import {put, takeEvery} from 'redux-saga/effects'
import {
    ADD_ALBUM_TO_SECTION, ADD_PLAYLIST_TO_SECTION,
    CREATE_SECTION,
    CREATE_SECTION_TO_PAGE,
    DEL_ALBUM_TO_SECTION,
    DEL_PLAYLIST_TO_SECTION,
    DEL_SECTION,
    GET_SECTION,
    GET_SECTION_LIST,
    RENAME_SECTION,

} from "../types";

import {createSectionAPI, listSectionAPI} from "../../api/sectionAPI";
import {getSection, setSection, setSectionList} from "../actions/sectionActions";
import {albumAPI, pageAPI, playlistAPI, sectionAPI} from "../../api/API";
import {onMessage} from "../../utils/onMessage";
import {setOutsideSection} from "../actions/albumActions";
import {getPageList} from "../actions/pageActions";
import {getOutSideSectionPlaylist, setOutSideSectionPlaylist} from "../actions/playlistActions";

export function* sectionSagaWatcher() {
    yield takeEvery(GET_SECTION_LIST, sagaGetList)
    yield takeEvery(CREATE_SECTION, sagaCreateSection)
    yield takeEvery(CREATE_SECTION_TO_PAGE, sagaCreateSectionToPage)
    yield takeEvery(DEL_SECTION, sagaDelSection)
    yield takeEvery(GET_SECTION, sagaGetSection)
    yield takeEvery(ADD_ALBUM_TO_SECTION, sagaAddAlbum)
    yield takeEvery(DEL_ALBUM_TO_SECTION, sagaDelAlbum)
    yield takeEvery(ADD_PLAYLIST_TO_SECTION, sagaAddPlaylist)
    yield takeEvery(DEL_PLAYLIST_TO_SECTION, sagaDelPlaylist)
    yield takeEvery(RENAME_SECTION, sagaRenameSection)
}

function* sagaGetList() {
    let response = yield listSectionAPI()
    yield put(setSectionList(response))
}

function* sagaGetSection({payload}) {
    let response = yield sectionAPI.getSection(payload)
    let data = yield response.data
    yield put(setSection(data))
    let type = yield data.idPage - 1
    let albums = yield albumAPI.outsideSection({idSection: payload, idTypeAudio: type})
    yield put(setOutsideSection(albums.data))
    let playlists = yield playlistAPI.OutsideSection({idSection: payload})
    console.log(playlists)
    yield put(setOutSideSectionPlaylist(playlists.data))
}

function* sagaCreateSection({payload}) {
    let response = yield createSectionAPI(payload)
    yield console.log(response)
    // yield put(setSectionList(response))
}

function* sagaCreateSectionToPage({payload}) {
    let response = yield sectionAPI.create({name:payload.name})
    if(response.statusText === "OK"){
        let response2 = yield pageAPI.addSections([{idPage: payload.selectPage, idSection: response.data }]);
        if(response2.statusText === "OK") {
            yield onMessage(response2)
            yield payload.successCreate(true, response.data)
        }else yield onMessage(response)
    }else yield onMessage(response)
    yield put(getPageList())
}

function* sagaAddAlbum({payload}) {
    let response = yield sectionAPI.addAlbum({IdSection: payload.idSection,
                                                    idAlbum: payload.id})
    yield onMessage(response, "альбом добавлен")
    yield put(getSection(payload.idSection))
}

function* sagaAddPlaylist({payload}) {
    let response = yield sectionAPI.addPlaylist({IdSection: payload.idSection,
        idPlaylist: payload.idPlaylist})
    yield onMessage(response, "альбом добавлен")
    yield put(getSection(payload.idSection))
}

function* sagaDelAlbum({payload}) {
    let response = yield sectionAPI.delAlbum(payload.id)
    yield onMessage(response, "альбом удален")
    yield put(getSection(payload.idSection))
}

function* sagaDelPlaylist({payload}) {
    let response = yield sectionAPI.delPlaylist(payload.id)
    yield put(getSection(payload.idSection))
    yield onMessage(response)
}

function* sagaDelSection({payload}) {
    let response = yield sectionAPI.del(payload)
    yield put(getPageList())
    yield onMessage(response)
}

function* sagaRenameSection({payload}) {
    let response = yield sectionAPI.rename(payload)
    yield put(getSection(payload.idSection))
    yield onMessage(response)
}


