import {propsAPI, trackAPI} from "./API";

export const findTracks = (data) => {
    return propsAPI.findTracks(data).then(response => {
        return response;
    });
}

export const deleteTrackAPI = (id) => {
    return trackAPI.delete(id).then(response => {
        return response;
    })
}

export const updateOrdersAPI = (data) => {
    return trackAPI.updateOrders(data).then(response => {
        return response;
    })
}

export const renameTrackAPI = (object) => {
    return trackAPI.rename(object).then(response => {
        return response;
    })
}
