import {
    ADD_ALBUM_TO_SECTION,
    ADD_PLAYLIST_TO_SECTION,
    CREATE_SECTION, CREATE_SECTION_TO_PAGE, DEL_ALBUM_TO_SECTION, DEL_PLAYLIST_TO_SECTION, DEL_SECTION, GET_SECTION,
    GET_SECTION_LIST,
    RENAME_SECTION, SET_SECTION,
    SET_SECTION_LIST
} from "../types";

export const getSectionList = (payload) => ({type: GET_SECTION_LIST, payload});
export const setSectionList = (payload) => ({type: SET_SECTION_LIST, payload});
export const getSection = (payload) => ({type: GET_SECTION, payload});
export const setSection = (payload) => ({type: SET_SECTION, payload});
export const createSection = (payload) => ({type: CREATE_SECTION, payload});
export const createSectionToPage = (payload) => ({type: CREATE_SECTION_TO_PAGE, payload});
export const renameSection = (payload) => ({type: RENAME_SECTION, payload});
export const addAlbumToSection = (payload) => ({type: ADD_ALBUM_TO_SECTION, payload});
export const addPlaylistToSection = (payload) => ({type: ADD_PLAYLIST_TO_SECTION, payload});
export const delSection = (payload) => ({type: DEL_SECTION, payload});
export const delAlbumToSection = (payload) => ({type: DEL_ALBUM_TO_SECTION, payload});
export const delPlaylistToSection = (payload) => ({type: DEL_PLAYLIST_TO_SECTION, payload});
