import React, {useEffect, useState} from "react";
import {Col, Row, Select} from "antd";
import {useDispatch, useSelector} from "react-redux";
import {
    createPropsTrack, deletePropsTrack,
    getAvailableList,
    getGroupList,
    getPropsList,
    updatePropsTrack
} from "../../redux/actions/propsActions";
import {UploadManagerPropsSelectors} from "../trackUploadManager/UploadManagerPropsSelectors";
import {getPropsTracksList, updatePropsTracksList} from "../../redux/actions/albumActions";
import {PropsSelector} from "./PropsSelector";
import {PropsSelectorGroup} from "./PropsSelectorGroup";

const {Option} = Select;
const styleHeader = {fontWeight: 'bold', fontSize: '13px', width: '100%'}

export const PropertiesManager = ({propsList, available, albumPropsTracks, idAlbum}) => {

    const dispatch = useDispatch()

    function addProps(data) {
        dispatch(createPropsTrack({data, idAlbum}))
    }

    function updateProps(idTrackProp, idNewProp) {
        dispatch(updatePropsTrack([{
            idTrackProp: idTrackProp,
            idNewProp: idNewProp
        }]))
    }

    function deleteProps(data) {
        dispatch(deletePropsTrack({data, idAlbum}))
    }

    const calculateGroupProps = (newProps, idGroup, selectGroupProperty) => {

        const propDelete = selectGroupProperty
            .filter(p => newProps.findIndex(n => n === p) < 0);

        const propAdd = newProps
            .filter(p => selectGroupProperty.findIndex(n => n === p) < 0);

        if (propDelete.length) {
            const deletePropToTrack = []
                .concat(...albumPropsTracks
                    .map(t => t.trackProps
                        .filter(p => p.idProp === propDelete[0])))
                .map(q => q.idTrackToProperties);

            deleteProps(deletePropToTrack)
        }

        if (propAdd.length) {
            const addPropToTrack = []
                .concat(...albumPropsTracks
                    .filter(a => a.trackProps
                        .findIndex(t => t.idProp === propAdd[0]) < 0
                    ))
                .map(t => ({'idTrack': t.idTrack, 'idProp': propAdd[0]}))

            addProps(addPropToTrack)
        }

    }


    const availableArr = available.map(sel => {
        return (sel.idGroupProp)
    })

    const width = parseInt(18 / availableArr.length)
    const bigWidth = availableArr % 5 + width

    const lable = available.map(sel => {
        const index = propsList.map(function (e) {
            return e.idGroupProp;
        }).indexOf(sel.idGroupProp);

        let props = propsList[index].properties;

        const selectGroup = []
            .concat(...albumPropsTracks
                .map(a => a.trackProps
                    .filter(t => t.idGroupProp === sel.idGroupProp)))
            .map(p => p.idProp);

        const selectGroupProperty = [...new Set(selectGroup)]
            .filter(p => selectGroup
                .filter(s => s === p).length === albumPropsTracks.length);

        return (
            <PropsSelectorGroup nameGroupProp={sel.nameGroupProp}
                                idGroupProp={sel.idGroupProp}
                                props={props}
                                width={width}
                                changeGroupProps={calculateGroupProps}
                                selectGroupProperty={selectGroupProperty}
                                key={sel.nameGroupProp}
            />
        )
    })

    const arr = albumPropsTracks.map(track => {
        return (
            <UploadManagerPropsSelectors
                key={track.idTrack}
                track={track}
                availableArr={availableArr}
                propsList={propsList}
                width={width}
                idAlbum={idAlbum}
                newProps={addProps}
                updateProps={updateProps}
                deleteProps={deleteProps}
            />
        )
    })


    return (
        <div>
            <Row gutter={10}>
                <Col span={6} style={{fontWeight: 'bold', fontSize: '13px', padding: "12px 3px"}}>Название</Col>
                {lable}
            </Row>
            {arr}
        </div>

    )

}
