import React, {useEffect} from "react";
import {Route, Switch, Redirect} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {AuthPage} from "./pages/AuthPage";
import App from "./App";
import {Spin} from "antd";
import {checkToken} from "./redux/actions/authActions";
import {AuditPage} from "./pages/AuditPage";
import {UserPage} from "./pages/UserPage";
import {AlbumPage} from "./pages/AlbumPage";
import EditAlbumPage from "./pages/EditAlbumPage";
import {PropertiesPage} from "./pages/PropertiesPage";
import {PerformerPage} from "./pages/PerformerPage";
import {PlaylistPage} from "./pages/PlaylistPage";
import {PagesPage} from "./pages/PagesPage";
import {SettingsPage} from "./pages/SettingsPage";
import {InterfacePage} from "./pages/InterfacePage";
import EditPlaylistPage from "./pages/EditPlaylistPage";
import {EditSectionPage} from "./pages/EditSectionPage";


export const Router = () => {
    let isAuth = useSelector(state => state.auth.isAuth);
    let isReady = useSelector(state => state.auth.isReady);
    const dispatch = useDispatch();

    useEffect(() => {
        if (!isReady) {
            dispatch(checkToken())
        }
    }, []);

    if (isAuth) {
        return <App/>
    }

    if (!isReady) {
        return (
            <div style={{width: '20px', margin: '250px auto'}} >
                <Spin tip="Loading..."/>
            </div>
        )
    }

    return (
        <Switch>
            <Route exact path={"/"} component={AuthPage}/>
            <Redirect to={"/"}/>
        </Switch>
    )
}

export const PageRoutes = () => (
    <Switch>
        <Route exact path='/album' component={AlbumPage}/>
        <Route exact path='/album/:id' component={EditAlbumPage}/>
        <Route exact path='/user' component={UserPage}/>
        <Route exact path='/audit/:type' component={AuditPage}/>
        <Route exact path='/properties' component={PropertiesPage}/>
        <Route exact path='/performer' component={PerformerPage}/>
        <Route exact path='/playlist' component={PlaylistPage}/>
        <Route exact path='/playlist/:id' component={EditPlaylistPage}/>
        <Route exact path='/pages' component={PagesPage}/>
        <Route exact path='/section/:id' component={EditSectionPage}/>
        <Route exact path='/settings' component={SettingsPage}/>
        <Route exact path='/interface' component={InterfacePage}/>
        <Redirect to={"/album"}/>
    </Switch>
)

