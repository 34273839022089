import React, {useEffect} from "react";
import {useDispatch} from "react-redux";
import {Button, Input, Space, Table, Tag} from "antd";
import { SearchOutlined } from '@ant-design/icons';
import {deleteAlbum, getAlbumList, getAlbumListUnchecked} from "../../redux/actions/albumActions";
import {NavLink} from "react-router-dom";

export const AlbumsList = ({albumType, albumList, check}) => {

    const dispatch = useDispatch()

    const albumDelete = async (idAlbum) => {
        let isDel = window.confirm("удалить альбом?");
        if (isDel) {
            dispatch(deleteAlbum(idAlbum))
        }
    }

    useEffect(() => {
        if(check){
            dispatch(getAlbumList())
        }else{
            dispatch(getAlbumListUnchecked())
        }

    }, [])

    const getColumnSearchProps = () => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
            <div style={{ padding: 8 }}>
                <Input
                    placeholder={`поиск`}
                    value={selectedKeys[0]}
                    onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => confirm()}
                    style={{ width: 188, marginBottom: 8, display: 'block' }}
                />
                <Space>
                    <Button
                        type="primary"
                        onClick={() => confirm()}
                        icon={<SearchOutlined />}
                        size="small"
                        style={{ width: 90 }}
                    >
                        Search
                    </Button>
                    <Button onClick={() => clearFilters()} size="small" style={{ width: 90 }}>
                        Reset
                    </Button>
                </Space>
            </div>
        ),
        filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
        onFilter: (value, record) =>
            record['name']
                ? record['name'].toString().toLowerCase().includes(value.toLowerCase())
                : ''
    });

    const columns = [
        {
            title: 'id',
            dataIndex: 'idAlbum',
            key: 'idAlbum',
        },
        {
            title: 'Название',
            dataIndex: 'name',
            key: 'name',
            render: (text, record) => (
                    <NavLink to={`/album/${record.idAlbum}`}>{record.name}</NavLink>
            ),
            ...getColumnSearchProps()
        },
        {
            title: 'Тип',
            dataIndex: 'idTypeAudio',
            key: 'idTypeAudio',
            filters: [
                {
                    text: 'музыка',
                    value: 1,
                },
                {
                    text: 'проповеди',
                    value: 2,
                },
                {
                    text: 'аудиокниги',
                    value: 3,
                },
                {
                    text: 'конференции',
                    value: 4,
                },
            ],
            onFilter: (value, record) => (record.idTypeAudio === value),
            render: (idTypeAudio) => {
                let nameType = albumType[idTypeAudio];
                let color = nameType === 'музыка' ? 'green' : 'volcano';
                if (nameType === 'проповеди') {
                    color = "geekblue"
                }
                return (
                    <Tag color={color} key={albumType}>
                        {nameType.toUpperCase()}
                    </Tag>
                );
            },
        },
        {
            title: '',
            key: 'action',
            render: (text, record) => (
                <Space size="middle">
                    <NavLink to={`/album/${record.idAlbum}`}>редактировать</NavLink>
                    <a onClick={()=>albumDelete(record.idAlbum)} key="delete" >
                        удалить
                    </a>
                </Space>
            ),
        },
    ];

    return (

                <Table dataSource={albumList} columns={columns} pagination={{ hideOnSinglePage: true }}/>

    )
}




