import {Button, Card, Form, Input, Select, Upload} from "antd";
import {UploadOutlined, InboxOutlined} from '@ant-design/icons';
import React, {useCallback} from "react";
import {UploadDemoForm} from "./UploadDemoForm";
import {createNewUser} from "../../redux/actions/userActions";
import {useDispatch} from "react-redux";

const {Option} = Select;

export const CreateUserForm = ({userType, hideModal}) => {

    const formItemLayout = {labelCol: {span: 6}, wrapperCol: {span: 16},}
    const tailLayout = {wrapperCol: {offset: 6, span: 16},};

    const [form] = Form.useForm();
    const dispatch = useDispatch()

    const create = async () => {
        const values = await form.validateFields();
        dispatch(createNewUser(values))
        cancel();
    }

    const cancel = () => {
        form.resetFields();
        hideModal()
    }

    let options = userType.map(type => (
        <Option key={type.id} value={type.id}>{type.name}</Option>
    ))

    return (
        <Card>
            <Form form={form} {...formItemLayout}>
                <Form.Item
                    name="login"
                    label="логин"
                    hasFeedback
                    rules={[
                        {required: true, message: 'Введите логин',},
                        {min: 3, message: 'Минимальная длина 3 символа'}
                    ]}
                >
                    <Input placeholder="Логин"/>
                </Form.Item>
                <Form.Item
                    name="password"
                    label="пароль"
                    hasFeedback
                    rules={[
                        {required: true, message: 'Пожалуйтса, введите пароль',},
                        {min: 8, message: 'Минимальная длина 8 символа'}
                    ]}
                >
                    <Input.Password placeholder="Пароль"/>
                </Form.Item>
                <Form.Item
                    name="idTypeAccount"
                    label="Тип"
                    hasFeedback
                    defaultActiveFirstOption={true}
                    value='4'
                    rules={[{required: true, message: 'Пожалуйста, выберите тип аккаунта!',},]}
                >
                    <Select placeholder="Выберите тип аккаунта">
                        {options}
                    </Select>
                </Form.Item>
                <Form.Item {...tailLayout}>
                    <Button type="primary" onClick={create}>Создать </Button>
                </Form.Item>
            </Form>

        </Card>
    )
}
