import React, {useEffect, useState} from "react";
import {Input, Select, Radio, Tag} from 'antd';
import {AudioOutlined} from '@ant-design/icons';
import {useDispatch, useSelector} from "react-redux";
import {getPropsList} from "../../redux/actions/propsActions";
import {FilterSearch} from "../search/FilterSearch";
import {findTracks} from "../../redux/actions/albumActions";
import {setReadyFalse} from "../../redux/actions/authActions";

const {Search} = Input;

export const SearchTrackForm = ({mode, editMode}) => {
    const dispatch = useDispatch();
    const [filter, setFilter] = useState(!mode)
    const [nameTrack, setNameTrack] = useState(null)
    const [idTypeAudio, setIdTypeAudio] = useState(null)
    const [idProps, setIdProps] = useState([])
    const typeAudio = useSelector(state => state.album.albumType)
    let propsList = useSelector(state => state.props.list)

    useEffect(() => {dispatch(getPropsList())}, []);

    const propsArrs = propsList.map(group => {
        return group.properties.map(p => {
            return {label: p.name, type: group.idGroupProp, value: p.idProp, color: 'green', key: p.idProp}
        })
    });

    const editFilter =() => setFilter(true)
    const optionsTags = [].concat(...propsArrs);
    const onSearch = (nameTrack) => {
        editMode(false)
        dispatch(findTracks({nameTrack, idTypeAudio, idProps}))
    };
    const onChangeText = (e) => {
        setNameTrack(e.currentTarget.value);
        dispatch(findTracks({nameTrack: e.currentTarget.value, idTypeAudio, idProps}))
    };
    const onChangeProps = (val) => {
        setIdProps(val.map(v => v.value));
        dispatch(findTracks({nameTrack, idTypeAudio, idProps: val.map(v => v.value)}))
    };
    const onChangeType = (e) => {
        setIdTypeAudio(e.target.value);
        dispatch(findTracks({nameTrack, idTypeAudio: e.target.value, idProps}))
    };

    let radioOptions = Object.entries(typeAudio).map(type => {
        return <Radio.Button value={type[0]}>{type[1]}</Radio.Button>
    });

    return (
        <>
            <Search placeholder="Введите название песни"
                    onChange={onChangeText}
                    onSearch={onSearch}
                    onFocus={()=>editMode(false)}
            />

            {!filter && !mode && <p onClick={editFilter}>Показать дополнительные параметры </p>}
            {filter && <FilterSearch onChangeType={onChangeType}
                          onChangeProps={onChangeProps}
                          optionsTags={optionsTags}
                          radioOptions={radioOptions}
            />}
        </>
    )
}
