import {convertData} from "./convertData";

export const transformType = (data) => ({
    'key': data.idTypeAccount,
    "id": data.idTypeAccount,
    "name": data.nameType,
    "description": data.description
})


export const transformAudit = (data) => ({
    "key": data.idAuditTrack || data.idAuditAlbum || data.idAuditAccount || data.idAuditProp,
    "number": data.idAuditTrack || data.idAuditAlbum || data.idAuditAccount || data.idAuditProp,
    "isSuccessful": data.isSuccessful ? 'успех' : 'ошибка',
    "login": data.executorLogin,
    "action": data.action,
    "id": data.idTrack || data.idAlbum || data.idAccount || data.idProp,
    "name": data.nameTrack || data.nameAlbum || data.accountToEdit || data.nameProp,
    "timeOperation": data.timeAction || convertData(data.timeOperation),
    "oldValue": data.oldValue
});

export const transformAuditAuth = (data) => {
    return ({
        "key": data.idAuthHistory,
        "idAuthHistory": data.idAuthHistory,
        "action": data.action,
        "executorLogin": data.executorLogin,
        "ipv4": data.ipv4,
        "userAgent": data.userAgent,
        "nameDevice": data.nameDevice,
        "timeAction": convertData(data.timeAction),
        "isSuccessful": data.isSuccessful ? 'успех' : 'ошибка'
    });
}

export const transformDataAlbum = (data) => ({
    key: data.idAlbum,
    idAlbum: Number(data.idAlbum),
    name: data.name,
    linkCover: data.linkCover || 'https://vk.com/images/camera_200.png',
    idTypeAudio: data.idTypeAudio,
    isChecked: data.isChecked
})

export const transformAlbumList = (data) => {
    return data.map(track => {
        return (
            {
                key: track.idAlbum,
                highQualityExist: track.highQualityExist,
                idAlbum: track.idAlbum,
                idTypeAudio: track.idTypeAudio,
                linkCover: track.linkCover,
                name: track.name
            }
        )
    })
}

export const transformTrackAlbum = (data) => {
    let tracks = sortTrack(data.tracks)
    console.log("tracks", tracks)
    return tracks.map((track, i) => {
        return {
            id: String(track.albumOrder),
            key: track.idTrack,
            content: {
                name: track.name,
                albumOrder: track.albumOrder,
                duration: track.duration,
                idTrack: track.idTrack,
                link: track.link
            }
        }
    })
};

const sortTrack = (tracks) => {
    return tracks.sort(function (a, b) {
        a = a.albumOrder || a.order
        b = b.albumOrder || b.order
        if (a > b) {
            return 1;
        }
        if (a < b) {
            return -1;
        }
        return 0;
    })
}


export const transformTrackPlaylist = (data) => {
    let tracks = sortTrackPlaylist(data.tracks)
    return tracks.map((track, i) => {
        return {
            id: String(track.order + i),
            key: track.idTrackToPlaylist,
            content: {
                name: track.name,
                albumOrder: track.order,
                duration: track.duration,
                idTrack: track.idTrackToPlaylist,
                link: track.link
            }
        }
    })
};

const sortTrackPlaylist = (tracks) => {
    return tracks.sort(function (a, b) {
        if (a.order > b.order) {
            return 1;
        }
        if (a.order < b.order) {
            return -1;
        }
        return 0;
    })
}


export const transformTracks = (data) => {
    console.log(data)
   return data.map(track => {
        return {
            id: String(track.idTrack),
            key: track.idTrack,
            content: {
                name: track.name,
                idTrack: track.idTrack,
                link: track.link
            }
        }
    })
};

export const transformUserList = (data) => {
    return data.map(track => (
        {
            key: track.login,
            login: track.login,
            typeAccount: track.typeAccount
        }
    ))
}

export const transformPropsList = (data) => {
    return data.map(props =>
        ({
            key: props[0].idGroupProp,
            idGroupProp: props[0].idGroupProp,
            name: props[0].name,
            properties: props[0].properties,
        })
    )
}

export const transformPlaylistList = (data) => {
    return data.map(p =>
        ({
            key: p.idPlaylist,
            idPlaylist: p.idPlaylist,
            name: p.name,
        })
    )
}
