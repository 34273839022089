import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {delSection, getSectionList} from "../redux/actions/sectionActions";
import {EditOutlined, EllipsisOutlined, SettingOutlined} from '@ant-design/icons';
import {Avatar, Button, Card, Col, Row, Tabs} from "antd";
import './PagesPage.css';
import {CreateSectionForm} from "../components/form/CreateSectionForm";
import {SectionList} from "../components/table/SectionList";
import {CreateSectionModal} from "../components/modal/CreateSectionModal";
import {getPageList} from "../redux/actions/pageActions";
import {getAlbumList} from "../redux/actions/albumActions";
import {DropSectionPage} from "../components/drop/DropSectionPage";
import {DropSectionItem} from "../components/drop/DropSectionItem";
import {NavLink} from "react-router-dom";
import {DropSectionTitle} from "../components/drop/DropSectionTitle";

const {Meta} = Card;

export const PagesPage = () => {

    const [visible, setVisible] = useState(false);
    const [activeTab, setActiveTab] = useState(0)
    let pageList = useSelector(state => state.page.pageList)
    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(getPageList())
    }, [])

    const {TabPane} = Tabs;

    function callback(key) {
        setActiveTab(key);
    }

    const okModalCreateSection = () => setVisible(true);
    const cancelModalCreateSection = () => setVisible(false);

    const sectionDelete = async (id) => {
        let isDel = window.confirm("удалить секцию?");
        if (isDel) dispatch(delSection(id))
    };

    const list = pageList.map(page => {
        const sections = page.pageSections.map((s, i) => ({id: `item-${i}`, content: s}))
        // console.log(sections)
        return (
            <TabPane tab={page.name} key={page.idPage}>
                <DropSectionTitle />
                <DropSectionPage pageList={sections} sectionDelete={sectionDelete}/>
            </TabPane>
        )}
    );



    return (
        <>
            <div className="card-container">
                <Button onClick={()=>{setVisible(true)}}>Создать новую секцию</Button><br/><br/>

                <Tabs onChange={callback} type="card">
                    {list}
                </Tabs>
            </div>

            <CreateSectionModal ok={okModalCreateSection} cancel={cancelModalCreateSection} visible={visible}/>
        </>
    )
}
