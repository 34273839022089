import {Layout, Menu} from "antd";
import React, {useState} from "react";
import {NavLink} from "react-router-dom";
import {useLocation} from "react-router-dom"

export const Sidebar = props => {
    const {Sider} = Layout;
    let location = useLocation().pathname.replace(/^\//, "")
    if(!location){location = 'album'}

    const [current, setCurrent] = useState(location)

    const handleClick = e => {
        console.log('click ', e);
        setCurrent(e.key);
    };

    return (
        <Sider width={200} className="site-layout-background">
            <Menu
                onClick={handleClick}
                mode="inline"
                defaultSelectedKeys={[current]}
                defaultOpenKeys={['album']}
                style={{height: '100%', borderRight: 0}}
                // collapsed={true}
            >
                <Menu.Item key="album"><NavLink exact to="/album">Альбомы</NavLink></Menu.Item>
                <Menu.Item key="playlist"><NavLink exact to="/playlist">Плейлисты</NavLink></Menu.Item>
                <Menu.Item key="pages"><NavLink exact to="/pages">Страницы</NavLink></Menu.Item>
                <Menu.Item key="prop"><NavLink exact to="/properties">Свойства</NavLink></Menu.Item>
                <Menu.Item key="performer"><NavLink exact to="/performer">Исполнители</NavLink></Menu.Item>
                <Menu.Item key="interface"><NavLink exact to="/interface">Дизайн</NavLink></Menu.Item>
                <Menu.Item key="settings"><NavLink exact to="/settings">Настройки</NavLink></Menu.Item>
                <Menu.Item key="user"><NavLink exact to="/user">Пользователи</NavLink></Menu.Item>
                <Menu.Item key="audit"><NavLink exact to="/audit/album">Отчеты</NavLink></Menu.Item>
            </Menu>
        </Sider>
    )
}
