import React from "react";
import {Form, Input, Button, Checkbox, Select} from "antd";
import {useDispatch} from "react-redux";
import {createSection} from "../../redux/actions/sectionActions";

const layout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 16 },
};
const tailLayout = {
    wrapperCol: { offset: 8, span: 16 },
};

const { Option } = Select;

export const CreateSectionForm = () => {

    const [form] = Form.useForm();
    const dispatch = useDispatch()

    const onFinish = values => {
        console.log('Success:', values);
        dispatch(createSection(values))
        form.resetFields();
    };

    const onFinishFailed = errorInfo => {
        console.log('Failed:', errorInfo);
    };

    return (
        <Form
            {...layout}
            form={form}
            name="basic"
            initialValues={{ remember: true }}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
        >
            <Form.Item
                label="Название секции"
                name="name"
                rules={[{ required: true, message: 'Пожалуйста, введите название!' }]}
            >
                <Input />
            </Form.Item>

            <Form.Item {...tailLayout}>
                <Button type="primary" htmlType="submit">
                    Создать
                </Button>
            </Form.Item>
        </Form>
    );
};

const plainOptions = [
    { label: 'Главная', value: '1' },
    { label: 'Музыка', value: '2' },
    { label: 'Проповеди', value: '3' },
    { label: 'Аудиокниги', value: '4' },
    { label: 'Конференции', value: '5' },
    ];


export const GetNameSection = ({onChange}) => {
    return(
        <>
            <Select defaultValue={"1"}  style={{ width: 200 }} onChange={onChange}>
                {plainOptions.map(o => <Option value={o.value}>{o.label}</Option>)}
            </Select>
        </>
    )
}
