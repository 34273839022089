import {SET_GENERAL_LIST, SET_OUTSIDE_SECTION_PLAYLIST, SET_PLAYLIST, SET_PLAYLIST_TRACKS} from "../types";

let initialState = {
    generalList: [],
    playlist: [],
    playlistTrack: [],
    outsideSection: []
};

const playlistReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_GENERAL_LIST:
            return {...state, generalList: action.payload}
        case SET_PLAYLIST:
            return {...state, playlist: action.payload}
        case SET_PLAYLIST_TRACKS:
            return {...state, playlistTrack: action.payload}
        case SET_OUTSIDE_SECTION_PLAYLIST:
            return {...state, outsideSection: action.payload}
        default:
            return state;
    }
};

export default playlistReducer;
