import {SET_AUDIT_LIST, SET_AUDIT_LIST_AUTH} from "../types";

let initialState = {
    auditList: [],
    authAudit: []
};

export default (state = initialState, action) => {
    switch (action.type) {
        case SET_AUDIT_LIST:
            return {...state, auditList: [...action.payload]}
        case SET_AUDIT_LIST_AUTH:
            return {...state, authAudit: [...action.payload]}
        default:
            return state;
    }
};
