import {} from "../types";
import {SET_PROPS_LIST} from "../types";
import {SET_GROUP_PROPS} from "../types";
import {SET_AVAILABLE_PROPS} from "../types";

let initialState = {
    groupList: [],
    available: [],
    list: []
};

const userReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_GROUP_PROPS:
            return {...state, groupList: [...action.payload]}
        case SET_AVAILABLE_PROPS:
            return {...state, available: [...action.payload]}
        case SET_PROPS_LIST:
            return {...state, list: [...action.payload]}
        default:
            return state;
    }
};

export default userReducer;
