import {DEL_NEW_TRACK, RENAME_UPLOAD_TRACK, SAVE_NEW_TRACKS, UPLOAD_NEW_TRACKS} from "../types";
import {trackAPI} from "../../api/API";
import {message} from "antd";
import {call, put, select, takeEvery} from "@redux-saga/core/effects";
import {selectAlbumId, selectAlbumIdTypeAudio, selectUploadTracks} from "../selectors";
import {
    addNewTracks,
    getDataAlbum, resetNumberLoaderItems,
    setClearTracks, setDelNewTracks, setLastOrder,
    setNewTracks,
    setNumberLoaderItems,
    uploadWindowOpen
} from "../actions/albumActions";
import {getDuration} from "../../utils/getDuration";


export function* uploadSagaWatcher() {
    yield takeEvery(RENAME_UPLOAD_TRACK, sagaRenameUploadTrack)
    yield takeEvery(SAVE_NEW_TRACKS, sagaSaveNewTracks)
    yield takeEvery(UPLOAD_NEW_TRACKS, sagaUploadNewTracks)
    yield takeEvery(DEL_NEW_TRACK, delNewTrack)
}

function* sagaUploadNewTracks({payload}) {
    const tracks = payload.tracks
    yield put(setNumberLoaderItems(tracks.length));
    yield put(uploadWindowOpen(true));
    let errorCount = 0;
    let order = yield payload.lastOrder;

    for (let i = 0; i < tracks.length; i++) {
        yield order++;
        const res = yield uploadTrackManager(tracks[i], order)
        yield put(addNewTracks(res));
        if (res.error) {
            yield order--;
            yield errorCount++;
            yield put(setNumberLoaderItems(tracks.length - errorCount));
        }
        // yield message.warning(e);

        // console.log(res)
        // if (!res.err) {
        //     yield put(addNewTracks(res));
        // } else {
        //     yield order--;
        //     yield message.warning(res.message);
        // }
        yield put(setLastOrder(order));
    }
    yield put(resetNumberLoaderItems());
}

function* uploadTrackManager(file, order) {

    let duration = yield getDuration(file.link)


    try {
        const result = yield trackAPI.unload(file);
        if (result.status === 200 && result.statusText === "OK") {
            return {
                key: order,
                name: file.name.replace(/\.[^.]+$/, ""),
                idTrack: `f${(+new Date).toString(16)}`,
                link: file.link,
                idStorage: result.data,
                size: file.size,
                albumOrder: order,
                duration: duration,
                error: false
            }
        } else {
            return {
                key: order,
                name: file.name.replace(/\.[^.]+$/, ""),
                idTrack: `f${(+new Date).toString(16)}`,
                link: file.link,
                size: file.size,
                albumOrder: null,
                duration: duration,
                error: "этот файл уже загружен"
            }
        }
    } catch (e) {
        return {
            key: order,
            name: file.name.replace(/\.[^.]+$/, ""),
            idTrack: `f${(+new Date).toString(16)}`,
            link: file.link,
            size: file.size,
            albumOrder: null,
            duration: duration,
            error: "этот файл уже загружен"
        }
    }
}

function* saveTracks({tracks, idAlbum, idTypeAudio}) {
    let success;
    for (let i = 0; i < tracks.length; i++) {
        if(!tracks[i].error){
            let response = yield trackAPI.new({
                name: tracks[i].name,
                idStorage: tracks[i].idStorage,
                duration: tracks[i].duration,
                AlbumOrder: tracks[i].albumOrder,
                idTypeAudio: idTypeAudio,
                idAlbum: idAlbum
            })
            if(response.status === 200 && response.statusText === "OK" ){
                success = true
            }else{
                message.error(response.statusText);
                success = response.statusText
            }
        }
    }
    return success;
}

function* sagaSaveNewTracks(action) {
    const idAlbum = yield select(selectAlbumId)
    const idTypeAudio = yield select(selectAlbumIdTypeAudio)
    console.log(idTypeAudio)
    const tracks = action.payload.data;
    const res = yield call(saveTracks, {tracks, idAlbum, idTypeAudio})
    yield put(uploadWindowOpen(false));
    yield put(setClearTracks());
    yield res ? message.success('файлы успешно загружены') : message.error(res)
    yield put(getDataAlbum(idAlbum))
}

function* sagaRenameUploadTrack({payload}) {
    const albumTracks = yield select(selectUploadTracks);
    const result = yield Array.from(albumTracks);
    const index = result.map(function (e) {
        return e.idTrack;
    }).indexOf(payload.id);
    yield result[index].name = payload.name;
    yield put(setNewTracks(result))
}

function* delNewTrack({payload}) {
    const result = yield Array.from(payload.newTracks);
    const index = yield result.map(function (e) {
        return e.idTrack;
    }).indexOf(payload.id);
    yield result.splice(index, 1);
    yield put(setDelNewTracks(result))
    if(result.length < 1) yield put(uploadWindowOpen(false))
}


