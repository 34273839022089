import React, {useCallback, useContext, useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {Card, Table} from 'antd';
import {getAuditList, getAuditListAuth} from "../../redux/actions/auditActions";
import {getPropsList} from "../../redux/actions/propsActions";

export const PropertiesTable = ({properties}) => {
    const {Column} = Table;

console.log(properties)
    return (
        <Card>
            <Table dataSource={properties}>
                <Column title="#" dataIndex="idProp" key="idProp"/>
                <Column title="Имя" dataIndex="name" key="name"/>
            </Table>
        </Card>
    )
}
