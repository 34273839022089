import { message} from 'antd';

export const onMessage = (response, mess) => {
    if(response.status < 300 && response.statusText === "OK" ){
        if(mess){
            message.success(mess);
        }else {
            message.success(response.data);
        }
    }else {
        message.error(response.data);
    }
}
