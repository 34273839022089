import { all } from 'redux-saga/effects'
import {sagaWatcher} from "./sagas";
import {playlistSagaWatcher} from "./playlistSagas";
import {trackSagaWatcher} from "./trackSagas";
import {performerSagaWatcher} from "./perfermerSagas";
import {albumSagaWatcher} from "./albumSagas";
import {sectionSagaWatcher} from "./sectionSagas";
import {pageSagaWatcher} from "./pageSagas";
import {uploadSagaWatcher} from "./uploadSagas";

export default function* rootSaga() {
    yield all([
        sagaWatcher(),
        albumSagaWatcher(),
        playlistSagaWatcher(),
        trackSagaWatcher(),
        performerSagaWatcher(),
        sectionSagaWatcher(),
        pageSagaWatcher(),
        uploadSagaWatcher()
    ])
}
