import {SET_QUEUE, GET_QUEUE, SET_ID_QUEUE} from "../types";


export const setQueue = (payload) => ({type: SET_QUEUE, payload});
export const setIdQueue = (payload) => ({type: SET_ID_QUEUE, payload});

export const getQueue = (payload) => ({
    type: GET_QUEUE,
    payload
})
