import * as axios from "axios";
import LocalStorageService from '../utils/LocalStorageService'

const localStorageService = LocalStorageService.getService();

// Перехватчик запроса
axios.interceptors.request.use(
    config => {
        const token = localStorageService.getAccessToken();
        if (token) {
            config.headers['Authorization'] = 'Bearer ' + token;
        }
        config.baseURL = "http://studiosib.ru:8082/admin/"
        // config.headers['Content-Type'] = 'application/json';
        return config;
    },
    error => {
        Promise.reject(error)
    });

//Перехватчик ответа
axios.interceptors.response.use((response) => {
    return response
}, function (error) {
    const originalRequest = error.config;

    if (error.response.status === 401 && originalRequest.url ===
        '/auth/refresh') {
        // router.push('/');
        return Promise.reject(error);
    }

    if (error.response.status === 401 && !originalRequest._retry) {
        originalRequest._retry = true;
        const refreshToken = localStorageService.getRefreshToken();
        const accessToken = localStorageService.getAccessToken();
        return axios.post('/auth/refresh',
            {
                "refreshToken": refreshToken,
                "accessToken": accessToken
            })
            .then(res => {
                if (res.status === 200) {
                    LocalStorageService.setToken(res.data);
                    axios.defaults.headers.common['Authorization'] = 'Bearer ' + localStorageService.getAccessToken();
                    return axios(originalRequest);
                }
            })
    }
    return Promise.reject(error);
});

export const authAPI = {
    signIn(data) {
        return axios.post('auth/signin', data)
    },
    checkAccessAdmin() {
        return axios.get('auth/checkAccessAdmin')
    }
}

export const albumAPI = {
    getAlbum(id) {
        return axios.get(`album/${id}`)
    },
    getAlbumList() {
        return axios.get('album/list')
    },
    getAlbumListUnchecked() {
        return axios.get('album/listUnchecked')
    },
    new(object) {
        let formData = new FormData();
        formData.append('name', object.name);
        formData.append('idTypeAudio', object.idTypeAudio);
        formData.append('highQualityExist', "false");
        return axios.post('Album/New', formData)
    },
    delete(id) {
        return axios.delete(`album/Delete?idAlbum=${id}`)
    },
    updateCover(object) {
        let formData = new FormData();
        formData.append('LargeCover', object.cover);
        // formData.append('Medium', object.cover);
        formData.append('idAlbum', object.idAlbum);
        return axios.put('Album/UpdateCover', formData)
        // IFormFile Medium ;
        // IFormFile Large;
    },
    rename(object) {
        return axios.post('Album/Rename', object)
    },
    setStatus(object) {
        return axios.post('Album/SetStatus', object)
    },
    outsideSection(object) {
        return axios.get(`Album/OutsideSection?idTypeAudio=${object.idTypeAudio}&idSection=${object.idSection}`)
    },
}

export const trackAPI = {
    rename(object) {
        return axios.post('track/rename', object)
    },
    new(object) {
        return axios.post('Track/New', object)
    },
    unload(track) {
        let formData = new FormData();
        formData.append("audio", track);
        return axios.put('Track/Unload', formData)
    },
    delete(id) {
        return axios.delete(`Track/Delete?idTrack=${id}`)
    },
    updateOrder(data) {
        return axios.post(`Track/UpdateOrder`, data);
    },
    updateOrders(object) {
        return axios.post('Track/UpdateOrders', object)
    }
}

export const userAPI = {
    getUserList() {
        return axios.get('account/list')
    },
    createNewUser(object) {
        return axios.post('Account/SignUpWithRole', object)
    },
    type() {
        return axios.get('account/types')
    }
}

export const accountAPI = {
    access() {
        return axios.get(`Account/CheckAccessAdmin`);
    }
}


export const auditAPI = {
    getAudit(type) {
        return axios.get(`HistoryAudit/${type}`);
    }
}

export const propsAPI = {
    getType() {
        return axios.get(`properties/Group`);
    },
    getPropsList(group) {
        console.log(group)
        // return axios.get(`properties/List?idGroupProp=${group}`);
        return axios.get(`properties/List`);
    },
    rename(type) {
        return axios.post(`properties/rename`, type);
    },
    create(Group) {
        return axios.post(`properties/new`, Group);
    },
    groupList() {
        return axios.get(`GroupProperties/List`);
    },
    groupAvailable(group) {
        return axios.get(`GroupProperties/Available?idTypeAudio=${group}`);
    },
    trackPropNew(data) {
        return axios.post(`TrackProperties/New`, data);
    },
    trackPropUpdate(data) {
        return axios.post(`TrackProperties/Update`, data);
    },
    trackPropDelete(data) {
        return axios.post(`TrackProperties/Delete`, data);
    },
    trackPropUpdateList(data) {
        return axios.post(`TrackProperties/UpdateList`, data);
    },
    getTrackProp(idTrack) {
        return axios.get(`TrackProperties/Get?idTrack=${idTrack}`);
    },
    getTrackPropList(idAlbum) {
        return axios.get(`TrackProperties/list?idAlbum=${idAlbum}`);
    },
    findTracks(data) {
        return axios.get(`Track/Find?${data}`);
    },
}


export const performerAPI = {
    getList() {
        return axios.get(`performer/list`);
    },
    create(data) {
        return axios.post(`performer/new`, data);
    },
    rename(data) {
        return axios.post(`performer/rename`, data);
    }
}

export const playlistAPI = {
    create(data) {
        return axios.put(`Playlist/new`, data);
    },
    rename(data) {
        return axios.post(`Playlist/Rename`, data);
    },
    UpdateCover(data) {
        return axios.post(`Playlist/UpdateCover`, data);
    },
    GeneralList(data) {
        return axios.get(`Playlist/GeneralList`, data);
    },
    OutsideSection({idSection}) {
        return axios.get(`Playlist/OutsideSection?idSection=${idSection}`);
    },
    UpdatePublicStatus(data) {
        return axios.post(`Playlist/UpdatePublicStatus`, data);
    },
    Delete(id) {
        return axios.delete(`Playlist/Delete?idPlaylist=${id}`,);
    },
    DeleteTrack(id) {
        return axios.delete(`Playlist/DeleteTrack?idTrackToPlaylist=${id}`);
    },
    AddTrack(data) {
        return axios.put(`Playlist/AddTrack`, data);
    },
    getPlaylist(id) {
        return axios.get(`Playlist/${id}`);
    },
    updateOrder(data) {
        return axios.post(`Playlist/UpdateOrder`, data);
    },
    updateOrders(object) {
        return axios.post('Playlist/UpdateOrders', object)
    }
}



export const sectionAPI = {
    list() {
        return axios.get(`Section/List`);
    },
    create(data) {
        return axios.put(`Section/Add`, data);
    },
    rename(data) {
        return axios.post(`Section/Rename`, data);
    },
    addPlaylist(data) {
        return axios.put(`Section/AddPlaylist`, data);
    },
    addAlbum(data) {
        return axios.put(`Section/AddAlbum`, data);
    },
    AddAlbums(data) {
        return axios.put(`Section/AddAlbums`, data);
    },
    del(id) {
        return axios.delete(`Section/Delete?idSection=${id}`);
    },
    delAlbum(id) {
        return axios.delete(`Section/DeleteAlbum?idSectionAlbum=${id}`);
    },
    delPlaylist(id) {
        return axios.delete(`Section/DeletePlaylist?idSectionPlaylist=${id}`);
    },
    getSection(id) {
        return axios.get(`Section/${id}`);
    },
}

export const pageAPI = {
    list() {
        return axios.get(`Page/List`);
    },
    addSection(data) {
        return axios.put(`Page/AddSection`, data);
    },
    addSections(data) {
        return axios.put(`Page/AddSections`, data);
    },
    del(id) {
        return axios.delete(`Page/DeleteSection?IdSectionPage=${id}`);
    },
    page(id) {
        return axios.delete(`Page/page/${id}`);
    },
    updateOrders(data) {
        return axios.post(`Page/UpdateOrders`, data);
    }
}

