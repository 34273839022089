import {Button, Form, Modal} from "antd";
import React, {useEffect, useState} from "react";
import {CreateSectionSteps} from "../steps/CreateSectionSteps";

export const CreateSectionModal = ({visible, cancel, ok}) => {

    return (
        <Modal
            visible={visible}
            title="Создание новой секции"
            onOk={ok}
            onCancel={cancel}
            footer={[]}
        >
            <CreateSectionSteps />
        </Modal>
    )
}
