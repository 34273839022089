import {playlistAPI} from "./API";

export const createPlaylistAPI = (idAlbum) => {
    return playlistAPI.create(idAlbum).then(response => {
        return response.data;
    });
}

export const renamePlaylistAPI = (data) => {
    return playlistAPI.rename(data).then(response => {
        return response.data;
    });
}

export const updateCoverPlaylistAPI = (data) => {
    return playlistAPI.UpdateCover(data).then(response => {
        return response.data;
    });
}

export const getGeneralListAPI = () => {
    return playlistAPI.GeneralList().then(response => {
        return response.data;
    });
}

export const addTrackToPlaylistAPI = (data) => {
    return playlistAPI.AddTrack(data).then(response => {
        return response.data;
    });
}

export const deleteTrackPlaylistAPI = (id) => {
    return playlistAPI.DeleteTrack(id).then(response => {
        return response.data;
    });
}

export const deletePlaylistAPI = (id) => {
    return playlistAPI.Delete(id).then(response => {
        return response.data;
    });
}

export const getPlaylistAPI = (id) => {
    return playlistAPI.getPlaylist(id).then(response => {
        return response.data;
    });
}

export const updateOrdersPlaylistAPI = (data) => {
    return playlistAPI.updateOrders(data).then(response => {
        return response.data;
    });
}
