import React, {useCallback, useContext, useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {Table} from 'antd';
import {getAuditList, getAuditListAuth} from "../../redux/actions/auditActions";

export const AuditTableAuth = (props) => {
    const { Column } = Table;
    const dispatch = useDispatch()
    let auditArr = useSelector(state => state.audit.authAudit)

    useEffect(() => {
        dispatch(getAuditListAuth());
    }, [])

    return(
        <Table dataSource={auditArr}>
            <Column title="#" dataIndex="idAuthHistory" key="idAuthHistory" />
            <Column title="Статус" dataIndex="isSuccessful" key="isSuccessful" />
            <Column title="Время" dataIndex="timeAction" key="timeAction" />
            <Column title="Логин" dataIndex="executorLogin" key="executorLogin" />
            <Column title="Операция" dataIndex="action" key="action" />
            <Column title="IP" dataIndex="ipv4" key="ipv4" />
            <Column title="Устройство" dataIndex="nameDevice" key="nameDevice" />
            <Column title="Браузер" dataIndex="userAgent" key="userAgent" />
        </Table>
    )
}
