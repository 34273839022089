import React from "react";
import {Grid, Modal} from "antd";
import {CreateUserForm} from "../form/CreateUserForm";

export const CreateUser = ({visible, hideModal, userType}) => {

    return (
        <>
            <Modal
                visible={visible}
                title="Создать новый аккаунт"
                onCancel={hideModal}
                footer={[]}
            >
                <CreateUserForm userType={userType} hideModal={hideModal}/>
            </Modal>
            <CreateUserForm userType={userType} />
        </>
    )
}
