import {SET_ERROR_MESSAGE, SET_LOADING_FALSE, SET_LOADING_TRUE, SET_MESSAGE} from "../types";

export const setLoadingTrue = () => ({type: SET_LOADING_TRUE});
export const setLoadingFalse = () => ({type: SET_LOADING_FALSE});
export const setMessage = (payload) => ({type: SET_MESSAGE, payload});
export const setErrorMessage = (payload) => ({type: SET_ERROR_MESSAGE, payload});



export const errorMessage = (mess) => (dispatch) => {
    dispatch(setErrorMessage(mess))
    setTimeout(dispatch(setErrorMessage('')), 2000)
}
