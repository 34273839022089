import {Button, Form, Input, InputNumber, Select, Upload} from "antd";
import {UploadOutlined} from '@ant-design/icons';
import React, {useEffect, useState} from "react";

export const CreateAlbumForm = props => {

    const {Option} = Select;
    const layout = {
        labelCol: {span: 6,},
        wrapperCol: {span: 16,},
    };


    const albumType = Object.entries(props.albumType);

    let type = albumType.map(res =>
        <Option key={res[0]} value={res[0]}>{res[1]}</Option>
    );

    return (
        <Form form={props.form} name="dynamic_rule">
            <Form.Item
                {...layout}
                name="name"
                label="Название"
                hasFeedback
                rules={[
                    {required: true, message: 'Введите названия альбома',},
                    {min: 4, message: 'Минимальная длина 4 символа'}
                ]}
            >
                <Input placeholder="Названия альбома"/>
            </Form.Item>
            <Form.Item
                {...layout}
                name="idTypeAudio"
                label="Тип альбома"
                hasFeedback
                rules={[
                    {required: true, message: 'Пожалуйста, выберите тип альбома!'},
                ]}
            >
                <Select placeholder="Выберите тип альбома">
                    {type}
                </Select>
            </Form.Item>

            <Form.Item
                {...layout}
                name="DateTime"
                label="Год выпуска"
                // hasFeedback
                rules={[
                    {required: true, message: 'Пожалуйста, выберите год выпуска!'},
                ]}
            >
                <InputNumber placeholder={null} min={1980} max={2021} defaultValue={2021}/>
            </Form.Item>
            {/*DateTime? ReleaseYear*/}

        </Form>
    )
}
