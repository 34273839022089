import {Col, Row, Switch} from "antd";
import React, {useState} from "react";

export const ChangeAlbumStatus = ({isChecked, onChange}) => {

    return (
        <Row>
            <Col span={6}>
                <Switch checked={isChecked} onChange={onChange} />
            </Col>
            <Col style={{marginLeft: 5}} span={17}>
                {/*{isChecked && <p style={{color: "#CCC"}}>опубликован</p>}*/}
                {/*{!isChecked && <p style={{color: "tomato"}}>не опубликован</p>}*/}
            </Col>
        </Row>
    )
}
