import {PageRoutes} from "../../router";
import React from "react";
import {Layout} from "antd";

export const Content = () => {
    const {Content} = Layout;
    return (
        <Content
            className="site-layout-background"
            style={{
                padding: 24,
                margin: 0,
                minHeight: 600,
                height: '100%'
            }}
        >
            <PageRoutes/>
        </Content>
    )
}
