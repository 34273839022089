import {Button, Card, Form, Input, Select, Upload} from "antd";
import React, {useCallback} from "react";
import {useDispatch} from "react-redux";

const {Option} = Select;

export const CreatePlaylistForm = ({hideModal, create}) => {

    const formItemLayout = {labelCol: {span: 6}, wrapperCol: {span: 16},}
    const tailLayout = {wrapperCol: {offset: 6, span: 16},};

    const [form] = Form.useForm();

    const onClick = async () => {
        const values = await form.validateFields();
        create(values)
        // cancel();
    }

    const cancel = () => {
        form.resetFields();
        // hideModal()
    }


    return (
        <Card>
            <Form form={form} {...formItemLayout}>
                <Form.Item
                    name="Name"
                    label="название"
                    hasFeedback
                    rules={[
                        {required: true, message: 'Введите название',},
                        {min: 3, message: 'Минимальная длина 3 символа'}
                    ]}
                >
                    <Input placeholder="Название"/>
                </Form.Item>
                <Form.Item {...tailLayout}>
                    <Button type="primary" onClick={onClick}>Создать </Button>
                </Form.Item>
            </Form>

        </Card>
    )
}
