import {} from "../types";
import {SET_PERFORMER_LIST} from "../types";

let initialState = {
    list: []
};

const performerReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_PERFORMER_LIST:
            return {...state, list: [...action.payload]}
        default:
            return state;
    }
};

export default performerReducer;
