import {
    ADD_TRACK_TO_PLAYLIST,
    CREATE_PLAYLIST,
    DELETE_PLAYLIST,
    DELETE_TRACK_PLAYLIST,
    GET_GENERAL_LIST,
    GET_OUTSIDE_SECTION_PLAYLIST,
    GET_PLAYLIST,
    RENAME_PLAYLIST,
    SET_GENERAL_LIST,
    SET_OUTSIDE_SECTION_PLAYLIST,
    SET_PLAYLIST,
    SET_PLAYLIST_TRACKS,
    UPDATE_ORDERS_PLAYLIST,
    UPDATE_PUBLIC_STATUS_PLAYLIST,
    UPLOAD_COVER_PLAYLIST
} from "../types";


export const createPlaylist = (payload) => ({type: CREATE_PLAYLIST, payload});
export const renamePlaylist = (payload) => ({type: RENAME_PLAYLIST, payload});
export const uploadCoverPlaylist = (payload) => ({type: UPLOAD_COVER_PLAYLIST, payload});
export const getGeneralPlaylist = (payload) => ({type: GET_GENERAL_LIST, payload});
export const setGeneralPlaylist = (payload) => ({type: SET_GENERAL_LIST, payload});
export const getOutSideSectionPlaylist = (payload) => ({type: GET_OUTSIDE_SECTION_PLAYLIST, payload});
export const setOutSideSectionPlaylist = (payload) => ({type: SET_OUTSIDE_SECTION_PLAYLIST, payload});
export const updatePublicStatusPlaylist = (payload) => ({type: UPDATE_PUBLIC_STATUS_PLAYLIST, payload});
export const getPlaylist = (payload) => ({type: GET_PLAYLIST, payload});
export const setPlaylist = (payload) => ({type: SET_PLAYLIST, payload});
export const setPlaylistTracks = (payload) => ({type: SET_PLAYLIST_TRACKS, payload});
export const addTrackToPlaylist = (payload) => ({type: ADD_TRACK_TO_PLAYLIST, payload});
export const deleteTrackToPlaylist = (payload) => ({type: DELETE_TRACK_PLAYLIST, payload});
export const deletePlaylist = (payload) => ({type: DELETE_PLAYLIST, payload});
export const updateOrderPlaylist = (payload) => ({type: UPDATE_ORDERS_PLAYLIST, payload});
