import {useCallback, useEffect, useState} from "react";
import {usePlay} from "./usePlay";
import {useSelector} from "react-redux";

export const useQueue = () => {
    const {isPlaying, setLink, onLoadedData, onEnded} = usePlay()
    const [list, setList] = useState([])
    const [id, setId] = useState([])
    const [index, setIndex] = useState(null)
    const [idPlaying, setIdPlaying] = useState(null)
    const queue = useSelector(state => state.album.albumTracks)

    // useEffect(() => {
    //     const audioNext = () => {
    //         setIndex(index + 1)
    //         const link = queue[index].content.link;
    //         setLink(link)
    //         onLoadedData()
    //     }
    // }, [])

    // useCallback(() => {
    //     onEnded(audioNext())
    // })

    const audioPlay = (idTrack) => {
        console.log("idTrack", idTrack)
        const i = findTrack(idTrack)
        setIndex(i)
        console.log("index", index)
        console.log("queue", queue)
        const link = queue[index].content.link;
        console.log("link", link)
        setLink(link)
        onLoadedData()
    }

    const findTrack = (id) => {
        return queue.map(function (e) {
            return e.content.idTrack;
        }).indexOf(id);
    }

    const audioNext = () => {
        setIndex(index + 1)
        const link = queue[index].content.link;
        setLink(link)
        onLoadedData()
    }

    function pause() {
        return undefined;
    }

    function play() {
        return undefined;
    }

    function playPause() {
        // isPlaying ? pause() : play()
    }

    function newTrack(idTrack) {
        setId(idTrack)
        audioPlay(idTrack)
    }

    function checkTrack(idTrack) {
        if (idTrack === id) {
            console.log(idTrack, id)
            audioPlay(idTrack)
        } else {
            newTrack(idTrack)
        }
    }

    useEffect(() => {
    }, [])

    return {playPause, checkTrack, newTrack}
}
