import React, {useEffect, useState} from "react";
import {DragDropContext, Droppable} from "react-beautiful-dnd";
import {useDispatch} from "react-redux";
import {updateOrders} from "../../redux/actions/albumActions";
import {Quote} from "../drop/Quote";

const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return result;
};

const QuoteList = React.memo(function QuoteList({quotes, EditItem, idAlbum, del, type}) {
    return quotes.map((quote, index) => (
        <Quote quote={quote}
               index={index}
               key={quote.id}
               EditItem={EditItem}
               idAlbum={idAlbum}
               del={del}
               type={type}
        />
    ));
});

export function Manager({tracks, EditItem, idAlbum, type, del}) {
    const dispatch = useDispatch()
    const [state, setState] = useState({quotes: tracks});

    useEffect(() => setState({quotes: tracks}), [tracks])

    const newOrder = (order) => dispatch(updateOrders({order, type}));

    function onDragEnd(result) {
        const destination = result.destination;
        if (!destination || destination.index === result.source.index) return;

        const quotes = reorder(
            state.quotes,
            result.source.index,
            destination.index
        );

        newOrder(quotes)
        setState({'quotes': quotes});
    }

    return (
        <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId="list">
                {provided => (
                    <div ref={provided.innerRef} {...provided.droppableProps}>
                        <QuoteList quotes={state.quotes}
                                   EditItem={EditItem}
                                   idAlbum={idAlbum}
                                   del={del}
                                   type={type}
                        />
                        {provided.placeholder}
                    </div>
                )}
            </Droppable>
        </DragDropContext>
    );
}
