import {Modal, Button, Skeleton, TimePicker, DatePicker} from 'antd';
import React, {useEffect, useState} from "react";
import {UploadTackList} from "../trackUploadManager/UploadTrackList";
import {delNewTracks, saveNewTracks} from "../../redux/actions/albumActions";
import {useDispatch} from "react-redux";

export const UploadTrackModal = ({
                                     visible,
                                     newTracks,
                                     trackSave,
                                     loading,
                                     setViseble,
                                     albumData,
                                     numberLoaderItems,
                                     onVisibleTimePicker
                                 }) => {

    const dispatch = useDispatch()

    const handleOk = (data) => {
        console.log(data);
        dispatch(saveNewTracks(data, albumData))
        // setViseble(false) //вроде это делает редьюсер
    };

    const handleCancel = e => {
        console.log(e);
        setViseble(false)
    };

    const trackDel = (id) => {
        dispatch(delNewTracks({id, newTracks}));
    };

    const [value, setValue] = useState(null);

    const onChange = time => {
        setValue(time);
        console.log(time)
    };

    let including = newTracks.find(t => t.error === false)

    // newTracks

    return (
        <div>
            <Modal
                title="Добавить треки"
                visible={visible}
                onOk={''}
                width={800}
                onCancel={handleCancel}
                cancelButtonProps={{disabled: true}}
                footer={[
                    <DatePicker
                        onChange={onChange}
                        showTime
                        format="YYYY-MM-DD HH:mm"
                    />,
                    <Button key="onOk"
                            loading={numberLoaderItems === 0 ? false : true }
                            disabled={!including}
                            onClick={() => {
                        handleOk(newTracks)
                    }}>
                        {numberLoaderItems > 0 ? `в очереди ${numberLoaderItems - newTracks.length} файлов` : "Сохранить"}
                    </Button>
                ]}
            >
                <Skeleton loading={loading} active>
                    <UploadTackList
                        newTracks={newTracks}
                        trackSave={trackSave}
                        trackDel={trackDel}
                    />
                </Skeleton>
            </Modal>
        </div>
    );
}
