import {AlbumsList} from "../components/table/AlbumList";
import React, {useState} from "react";
import {Button, Card, Col, PageHeader, Spin, Table} from "antd";
import {CreateAlbumModal} from "../components/modal/CreateAlbumModal";
import {useDispatch, useSelector} from "react-redux";
import {Tabs} from 'antd';

const {TabPane} = Tabs;


export const AlbumPage = props => {
    const [visible, setVisible] = useState(false)
    const [activeKey, setActiveKey] = useState("2")
    let loading = useSelector(state => state.info.loading)
    let albumList = useSelector(state => state.album.albumList)
    let albumListUnchecked = useSelector(state => state.album.albumListUnchecked)
    let albumType = useSelector(state => state.album.albumType)

    function callback(key) {
        setActiveKey(key);
    }

    const showModal = () => {
        setVisible(true)
    };

    const hideModal = () => {
        setVisible(false)
    };

    return (
        <Spin spinning={loading} tip="Loading...">
                <PageHeader
                    ghost={false}
                    // onBack={() => window.history.back()}
                    title="Альбомы"
                    extra={[
                        <Button onClick={showModal} key="create" type="primary">
                            Создать
                        </Button>,
                    ]}
                >
                    <Tabs defaultActiveKey="1" activeKey={activeKey} onChange={callback} onTabClick={callback}>
                        <TabPane tab="Опубликованные" key="1">
                            <AlbumsList albumType={albumType} albumList={albumList} check={true}/>
                        </TabPane>
                        <TabPane tab="На модерации" key="2">
                            <AlbumsList albumType={albumType} albumList={albumListUnchecked} check={false}/>
                        </TabPane>
                    </Tabs>
                    <CreateAlbumModal visible={visible} hideModal={hideModal} albumType={albumType} setActiveKey={setActiveKey}/>
                </PageHeader>
        </Spin>

    )
}
