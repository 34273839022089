import {SET_DATA_USER, SET_USER_LIST, SET_USER_TYPE} from "../types";

let initialState = {
    type: ["", "музыка", "проповеди", "аудиокниги"],
    userData : [],
    userList: [],
    userType: []
};

const userReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_DATA_USER:
            return {...state, userData: {...action.payload}}
        case SET_USER_LIST:
            return {...state, userList: [...action.payload]}
        case SET_USER_TYPE:
            return {...state, userType: [...action.payload]}
        default:
            return state;
    }
};

export default userReducer;
