import React, {useCallback, useEffect, useState} from "react";
import {PageHeader} from "antd";
import {SearchTrackForm} from "../form/SearchTrackForm";
import {AlbumsList} from "../table/AlbumList";
import {findTracks, getAlbumList, getDataAlbum} from "../../redux/actions/albumActions";
import {useDispatch, useSelector} from "react-redux";
import {AlbumGrid} from "../table/AlbumGrid";
import {addTrackToPlaylist} from "../../redux/actions/playlistActions";
import {TrackList} from "../playlist/TrackList";

export const SearchTrackManager = ({idPlaylist}) => {
    const dispatch = useDispatch()
    let albumList = useSelector(state => state.album.albumList)
    let findTrack = useSelector(state => state.album.findTrack)
    let albumTracks = useSelector(state => state.album.albumTracks);
    const [mode, setMode] = useState(true)

    useEffect(() => {
        dispatch(getAlbumList())
    }, [])

    const getAlbum = (idAlbum) => {
        dispatch(getDataAlbum(idAlbum))
    };

    const addToPlaylist = (id) => {
        dispatch(addTrackToPlaylist({
            idTrack: id,
            idPlaylist: idPlaylist
        }))
    };

    const editMode = (mode) => setMode(mode)

    return (
        <PageHeader
            ghost={false}
            // onBack={() => window.history.back()}
            title={'Добавить треки'}
            extra={[
            ]}
        >
            <SearchTrackForm mode={mode} editMode={editMode}/>
            <br/>
            {mode && <AlbumGrid albumList={albumList} albumTracks={albumTracks} setIdAlbum={getAlbum}
                       addToPlaylist={addToPlaylist}/>}
            {!mode && <TrackList data={findTrack} addToPlaylist={addToPlaylist}/>}
        </PageHeader>
    )
};
