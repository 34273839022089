import React from "react";
import {Col, Row, Select} from "antd";
import {useDispatch} from "react-redux";
import {createPropsTrack, deletePropsTrack, updatePropsTrack} from "../../redux/actions/propsActions";
import {PropsSelector} from "../trackPropsManager/PropsSelector";

export const UploadManagerPropsSelectors = props => {

    const {track, idAlbum, propsList, availableArr, width, updateProps, newProps, deleteProps} = props;

    const selectors = availableArr.map(a => {
        const index = propsList.map(function (e) {
            return e.idGroupProp;
        }).indexOf(a);
        return <PropsSelector children={propsList[index]}
                              idGroupProp={a}
                              props={track.trackProps}
                              idTrack={track.idTrack}
                              updateProps={updateProps}
                              newProps={newProps}
                              deleteProps={deleteProps}
                              width={width}
                              key={track.idTrack}
        />
    })

    return (
        <>
            <Row style={{marginBottom: 5}} gutter={10}>
                <Col span={6}>{track.name}</Col>
                {selectors}
            </Row>
        </>
    )
}
