import {call, put, takeEvery} from 'redux-saga/effects'
import {
    ADD_PROPS,
    CREATE_PROPS_TRACK, DEL_NEW_TRACK, DELETE_PROPS_TRACK, DELETE_TRACK, FIND_TRACKS, GET_PROPS_LIST,
    RENAME_TRACK, SET_PROPS_LIST, UPDATE_ORDERS, UPDATE_ORDERS_TRACK_ALBUM, UPDATE_PROPS_TRACK, UPDATE_PROPS_TRACK_LIST,
} from "../types";
import {
    getDataAlbum, getPropsTracksList, setDelNewTracks, setFindTracks, updateOrdersAlbum
} from "../actions/albumActions";
import {message} from 'antd';
import {transformTracks} from "../../utils/transformAPI";

import {
    sd__trackPropNew,
    sd__trackPropUpdate,
} from "../../api/setData";
import {deleteTrackAPI, findTracks, renameTrackAPI, updateOrdersAPI} from "../../api/trackAPI";
import {onMessage} from "../../utils/onMessage";
import {propsAPI, trackAPI} from "../../api/API";
import {getPropsList, setPropsList} from "../actions/propsActions";
import {updateOrderPlaylist} from "../actions/playlistActions";

export function* trackSagaWatcher() {
    yield takeEvery(UPDATE_PROPS_TRACK, sagaTrackPropUpdate)
    yield takeEvery(DELETE_PROPS_TRACK, sagaTrackPropDelete)
    yield takeEvery(UPDATE_PROPS_TRACK_LIST, sagaTrackPropUpdateList)
    yield takeEvery(CREATE_PROPS_TRACK, sagaTrackPropNew)
    yield takeEvery(FIND_TRACKS, sagaSearchTrack)
    yield takeEvery(DELETE_TRACK, deleteTrack)
    yield takeEvery(UPDATE_ORDERS_TRACK_ALBUM, updateOrdersTracks);
    yield takeEvery(RENAME_TRACK, renameTrack);
    yield takeEvery(ADD_PROPS, sagaNewProps);
    yield takeEvery(GET_PROPS_LIST, sagaGetPropsList)
    yield takeEvery(UPDATE_ORDERS, sagaUpdateOrders)
}

function* sagaGetPropsList({payload}) {
    let response = yield propsAPI.getPropsList(payload)
    yield put(setPropsList(response.data))
}

function* sagaNewProps({payload}) {
    const response = yield propsAPI.create(payload.values)
    yield onMessage(response);
    yield put(getPropsList())
}

function* sagaTrackPropNew({payload}) {
    let response = yield propsAPI.trackPropNew(payload.data);
    yield put(getPropsTracksList(payload.idAlbum))
    yield onMessage(response)
}

function* sagaTrackPropUpdate({payload}) {
    let response = yield propsAPI.trackPropUpdate([{idTrackProp: payload.idTrackProp, idNewProp: payload.idNewProp}]);
    yield onMessage(response)
}

function* sagaTrackPropDelete({payload}) {
    let response = yield propsAPI.trackPropDelete(payload.data);
    yield put(getPropsTracksList(payload.idAlbum))
    yield onMessage(response, 'свойство удалено')
}

function* sagaTrackPropUpdateList({payload}) {
    let response = yield propsAPI.trackPropUpdateList({idNewProp: payload.idNewProp, idAlbum: payload.idAlbum})
    yield onMessage(response)
    yield put(getPropsTracksList(payload.idAlbum))
}

function* sagaSearchTrack({payload}) {
    console.log(payload)
    // nameTrack=А&idTypeAudio=1&idProps=28
    let nameTrack = yield payload.nameTrack;
    let idTypeAudio = yield payload.idTypeAudio;
    let idProps = yield payload.idProps;
    let requestString = ''
    if (!!nameTrack) {
        requestString = requestString + `nameTrack=${nameTrack}&`;
    }
    if (!!idTypeAudio) {
        requestString = yield requestString + `idTypeAudio=${idTypeAudio}&`;
    }
    if (idProps.length > 0) {
        for (let i = 0; i < idProps.length; i++) {
            requestString = yield requestString + `idProps=${idProps[i]}&`;
        }
    }
    if (!!requestString) {
        console.log(requestString)
        let response = yield propsAPI.findTracks(requestString)
        console.log(response)
        let tracks = yield transformTracks(response.data)
        yield put(setFindTracks(tracks))
    }

}

function* deleteTrack({payload}) {
    let response = yield deleteTrackAPI(payload.id)
    yield onMessage(response)
    yield put(getDataAlbum(payload.idAlbum))
}

function* updateOrdersTracks({payload}) {
    let data = yield {"TrackToOrderList": payload};
    yield trackAPI.updateOrders(data)
}

function* renameTrack({payload}) {
    let response = yield renameTrackAPI(payload)
    yield onMessage(response)
    // yield put(getDataAlbum(payload.idAlbum))
}

function* sagaUpdateOrders({payload}) {
    if(payload.type === 'album'){
        const order = payload.order.map((track, i) =>
            ({IdOrder: i + 1, IdTrack: track.content.idTrack})
        );
        yield put(updateOrdersAlbum(order))
    }else if(payload.type === 'playlist'){
        let order = payload.order.map((track, i) =>
            ({IdTrackToPlaylist: track.content.idTrack, IdNewOrder: i + 1})
        );
        yield put(updateOrderPlaylist(order))
    }else return
}
