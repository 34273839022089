import React, {useState} from "react";
import {Steps, Button, message, Input, Result} from 'antd';
import './CreateSectionSteps.css';
import {CreateUserForm} from "../form/CreateUserForm";
import {CreateSectionForm, GetNameSection} from "../form/CreateSectionForm";
import {useDispatch, useSelector} from "react-redux";
import {createSectionToPage} from "../../redux/actions/sectionActions";
import {NavLink} from "react-router-dom";

export const CreateSectionSteps = () => {

    const [name, setName] = useState('');
    const [selectPage, setSelectPage] = useState(1);
    const [toggleModal, setToggleModal] = useState(false);
    const [sectionId, setSectionId] = useState(null);
    const dispatch = useDispatch();

    function onChange(checkedValues) {
        setSelectPage(checkedValues)
    }

    function onInput(e) {
        setName(e.currentTarget.value)
    }

    const successCreate = (status, id) => {
        updateForm()
        if(status){
            setToggleModal(true)
            setSectionId(id)
        }else message.error('произошла ошибка')
    }

    const updateForm = () => {
        setName([])
        setToggleModal(false)
    }

    function createSection() {
        if(name.length > 3 ) {
            dispatch(createSectionToPage({name, selectPage, successCreate}))
        } else message.error('Название слишком короткое');
    }

    if(toggleModal) return (
        <Result
            status="success"
            title="Секция успешно создана!"
            extra={[
                <NavLink to={`/section/${sectionId}`}><Button type="primary" key="add">
                    Редактировать
                </Button></NavLink>,
                <Button key="create" onClick={updateForm}>Создать новую</Button>,
            ]}
        />
    )

    return (
        <>
            <Input placeholder="Введите название секции" onChange={onInput} value={name} maxLength={25}/><br /><br />
            <GetNameSection onChange={onChange} /><br /><br />
            <Button type="primary" onClick={createSection}>Создать секцию</Button>
        </>
    )
};
