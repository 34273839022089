import {authAPI} from "../../api/API";
import LocalStorageService from '../../utils/LocalStorageService'
import {SET_AUTH_FALSE, SET_AUTH_TRUE, SET_READY_FALSE, SET_READY_TRUE} from "../types";
import {message} from "antd";

export const setAuthTrue = (payload) => ({type: SET_AUTH_TRUE});
export const setAuthFalse = (payload) => ({type: SET_AUTH_FALSE});
export const setReadyTrue = (payload) => ({type: SET_READY_TRUE});
export const setReadyFalse = (payload) => ({type: SET_READY_FALSE});

export const auth = (type) => (dispatch) => {
    authAPI.signIn(type)
        .then(response => {
            if(response.status === 200 && response.statusText === "OK"){
                LocalStorageService.setToken(response.data);
                dispatch(setAuthTrue())
            }else {
                message.warning("произошла ошибка");
            }

        })
        .catch(response => {
            message.warning(response);
            dispatch(setAuthFalse())
        })
}

export const checkToken = () => (dispatch) => {
    const token = LocalStorageService.getAccessToken();
    if (token) {
        authAPI.checkAccessAdmin()
            .then(response => {
                if(response.data === "Success" && response.statusText === "OK"){
                    dispatch(setAuthTrue());
                    dispatch(setReadyTrue());
                }
            })
            .catch(response => {
                // LocalStorageService.clearToken();
                dispatch(setReadyTrue())
                dispatch(setAuthFalse())
            })
    }else {
        dispatch(setReadyTrue())
    }
}

export const logout = () => (dispatch) => {
    LocalStorageService.clearToken();
    dispatch(setAuthFalse())
}
