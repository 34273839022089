import React, {useEffect, useState} from "react";
import {useParams, useHistory} from "react-router-dom"
import {Tabs} from 'antd';
import {AuditTable} from "../components/table/AuditTable";
import {AuditTableAuth} from "../components/table/AuditTableAuth";
import {getAuditList} from "../redux/actions/auditActions";

const {TabPane} = Tabs;

export const AuditPage = () => {
    const [type, setType] = useState('album')
    const history = useHistory()
    let param = useParams().type


    useEffect(() => {
        setType(param)
    }, [param])

    function callback(key) {
        setType(key)
        history.push(key)
    }

    return (
        <div className="row">
            <Tabs defaultActiveKey="1" onChange={callback}>
                <TabPane tab="Альбомы" key="album">
                    <AuditTable type={type}/>
                </TabPane>
                <TabPane tab="Треки" key="track">
                    <AuditTable type={type}/>
                </TabPane>
                <TabPane tab="Свойства" key="property">
                    <AuditTable type={type}/>
                </TabPane>
                <TabPane tab="Аккаунты" key="account">
                    <AuditTable type={type}/>
                </TabPane>
                <TabPane tab="Сессии" key="auth">
                    <AuditTableAuth type={"auth"}/>
                </TabPane>
            </Tabs>
        </div>
    )
}
