import React, {useEffect, useState} from "react";
import {Col, Modal, PageHeader, Row} from "antd";
import {useDispatch, useSelector} from "react-redux";
import {createPlaylist, getGeneralPlaylist, renamePlaylist} from "../redux/actions/playlistActions";
import {CreatePlaylistForm} from "../components/form/CreatePlaylistForm";
import {PlaylistTable} from "../components/table/PlaylistTableTest";
// import {CreateUserForm} from "../components/form/CreateUserForm";

export const PlaylistPage = ({hideModal}) => {
    const [visible, setVisible] = useState(false)

    const dispatch = useDispatch()
    let playlists = useSelector(state => state.playlist.generalList)

    useEffect(() => {
        dispatch(getGeneralPlaylist())
    }, [])

    const create = (data) => {
        dispatch(createPlaylist(data))
    }

    const rename = (data) => {
        dispatch(renamePlaylist(data))
    }

    const onEdit = (e) => {
        setVisible(true)
    }

    return (
        <>
            <Row gutter={15}>
                <Col lg={24} xl={14}>
                    <PageHeader
                        ghost={false}
                        onBack={() => window.history.back()}
                        title="Плейлисты"
                        extra={[]}
                    >
                        <PlaylistTable playlists={playlists} onEdit={onEdit} rename={rename}/>
                    </PageHeader>
                </Col>
                <Col lg={0} xl={10}>
                    <PageHeader
                        ghost={false}
                        title="Создать плейлист"
                        extra={[]}
                    >
                        <CreatePlaylistForm create={create}/>
                    </PageHeader>
                </Col>
            </Row>

            <Modal
                visible={visible}
                title="Создать новый аккаунт"
                onCancel={hideModal}
                footer={[]}
            >
            </Modal>
        </>
    )
}
