import {SET_SECTION, SET_SECTION_LIST} from "../types";

let initialState = {
    sectionList: [],
    section: []
};

const sectionReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_SECTION_LIST:
            return {...state, sectionList: action.payload}
        case SET_SECTION:
            return {...state, section: action.payload}
        default:
            return state;
    }
};

export default sectionReducer;
