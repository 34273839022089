import React, {useEffect, useState} from "react";
import {TrackList} from "../playlist/TrackList";

let covercss = {width: '80px'}
let gridcss = {
    display: 'grid',
    justifyContent: 'space-between',
    gridTemplateColumns: 'repeat(auto-fill, 80px)',
    // gridTemplateColumns: 'auto',
    gridColumnGap: '10px',
    gridRowGap: '20px',
    marginTop: '30px',
}

export const AlbumGrid = ({albumTracks, albumList, setIdAlbum, addToPlaylist}) => {
    const [mode, setMode] = useState(false)

    const onClick = (id) => {
        setIdAlbum(id)
        setMode(true)
    }
    albumList.length = 20
    const grid = albumList.map(album => {
        return <div key={album.idAlbum} onClick={()=> {onClick(album.idAlbum)}}>
            <div><img style={covercss} src={album.linkCover}/></div>
            <div>{album.name.slice(0, 10)}</div>
        </div>
    })

    return !mode
        ? <div style={gridcss}>{grid}</div>
        : <TrackList data={albumTracks} addToPlaylist={addToPlaylist}/>

}




