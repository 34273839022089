import {Col, Select} from "antd";
import React, {useCallback, useEffect, useState} from "react";
import {createPropsTrack} from "../../redux/actions/propsActions";
import {sd__trackPropNew, sd__trackPropUpdate} from "../../api/setData";

const {Option} = Select;

export const PropsSelector = ({children, idGroupProp, props, width, idTrack, updateProps, newProps, deleteProps}) => {
    const [state, setState] = useState([])
    const [mapSelect, setMapSelect] = useState([{'idTrackToProperties': 123, 'idProp': 1}])

    useEffect(() => {
        const result = props.filter(p => p.idGroupProp === idGroupProp)

        setState(result.map(r => r.idProp))
        setMapSelect(result.map(prop => ({'idTrackToProperties': prop.idTrackToProperties, 'idProp': prop.idProp})))
    }, [props])

    const handleChange = (e) => {
        if (mapSelect.length === 1 && e.length === 1) {
            updateProps(mapSelect[1].idTrackToProperties, e)
            setState([e])
        } else newProps(e)
        setState([e])
    }

    const handleChangeMulti = (e) => {

        let addArr = e.filter(i => !state.includes(i))
        let delArr = state.filter(i => !e.includes(i))

        if (addArr.length === 1) {
            newProps([{idTrack, 'idProp': addArr[0]}])
            setState([...state, addArr[0]])
        }

        if (delArr.length === 1) {
            let index = mapSelect.findIndex(m => m.idProp === delArr[0])
            if (index >= 0) deleteProps([mapSelect[index].idTrackToProperties])
        }
    }


    let options = children.properties.map(prop => {
        return (<Option value={prop.idProp} label={prop.name} key={prop.idProp}>{prop.name}</Option>)
    })

    // if (idGroupProp === 6 || state.length > 1){
    return (
        <Col span={width}>
            <Select
                mode="multiple"
                autoClearSearchValue={false}
                notFoundContent={false}
                optionLabelProp="label"
                optionFilterProp="label"
                size={'small'}
                style={{width: '100%'}}
                placeholder="Выберите"
                value={state}
                onChange={handleChangeMulti}
            >
                {options}
            </Select>
        </Col>
    )
    // }

    // return (
    //     <Col span={width}>
    //         <Select
    //             size={'small'}
    //             style={{width: '100%'}}
    //             placeholder="Выберите"
    //             value={state}
    //             onChange={handleChange}
    //         >
    //             {options}
    //         </Select>
    //     </Col>
    // )
}
