import React, {useEffect, useMemo, useState} from 'react';
import {useDropzone} from 'react-dropzone';
import {useDispatch, useSelector} from "react-redux";
import {uploadNewTracks} from "../../redux/actions/albumActions";

const baseStyle = {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '20px',
    borderWidth: 2,
    borderRadius: 2,
    borderColor: '#eeeeee',
    borderStyle: 'dashed',
    backgroundColor: '#fafafa',
    color: '#bdbdbd',
    outline: 'none',
    transition: 'border .24s ease-in-out'
};

const activeStyle = {
    borderColor: '#2196f3'
};

const acceptStyle = {
    borderColor: '#00e676'
};

const rejectStyle = {
    borderColor: '#ff1744'
};

function DropUploadFile(props) {
    const dispatch = useDispatch();
    let lastOrder = useSelector(state => state.album.lastOrder);
    const [files, setFiles] = useState([]);
    const {
        getRootProps,
        getInputProps,
        isDragActive,
        isDragAccept,
        isDragReject,
        acceptedFiles,
    } = useDropzone({
        accept: 'audio/*',
        onDrop: acceptedFiles => {
            setFiles(acceptedFiles.map(file => Object.assign(file, {
                link: URL.createObjectURL(file)
            })));
        }
    });

    const style = useMemo(() => ({
        ...baseStyle,
        ...(isDragActive ? activeStyle : {}),
        ...(isDragAccept ? acceptStyle : {}),
        ...(isDragReject ? rejectStyle : {})
    }), [
        isDragActive,
        isDragReject
    ]);

    useEffect(() => {
        if(files.length != 0) {
            const sorted = [].slice.call(files).sort((a, b) => {
                if (a["name"] === b["name"]) {
                    return 0;
                }
                return a["name"] > b["name"] ? 1 : 1 * -1;
            });
            dispatch(uploadNewTracks({tracks: sorted, lastOrder}))
        }
    }, [files])

    return (
        <div>
            <div {...getRootProps({style})}>
                <input {...getInputProps()} />
                <p>Перетащите сюда файлы, или нажмите для выбора</p>
            </div>
        </div>
    );
}

export default DropUploadFile



// switch(typeof field) {
//     case 'number':
//         return field < 0 ?
//             ((a, b) => (a < b && 1) || (a > b && -1) || 0) :
//             ((a, b) => (a < b && -1) || (a > b && 1) || 0);
//     case 'string':
//         return (a, b) => (a[field] < b[field] && -1) || (a[field] > b[field] && 1) || 0;
// }
