import React from "react";
import {Table} from "antd";

export const PerformerTable = ({performerList}) => {

    console.log(performerList)

    const columns = [
        {
            title: 'id',
            dataIndex: 'idPerformer',
            key: 'idPerformer',
        },
        {
            title: 'название',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: 'список треков',
            dataIndex: 'performerToTrack',
            key: 'performerToTrack',
        },
    ];

    return (
        <Table dataSource={performerList} columns={columns} />
    )
}
