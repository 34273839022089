import {put, takeEvery} from 'redux-saga/effects'
import {
    CREATE_PERFORMER,
    GET_PERFORMER_LIST, RENAME_PERFORMER,

} from "../types";

import {message} from 'antd';
import {
    gd__ListPerformer
} from "../../api/getData";
import {
    sd__createPerformer,
    sd__renamePerformer,
} from "../../api/setData";
import {setPerformerList} from "../actions/performerActions";

export function* performerSagaWatcher() {
    yield takeEvery(GET_PERFORMER_LIST, sagaGetListPerformer)
    yield takeEvery(CREATE_PERFORMER, sagaCreatePerformer)
    yield takeEvery(RENAME_PERFORMER, renamePerformer)
}

function* sagaGetListPerformer() {
    let response = yield gd__ListPerformer()
    yield put(setPerformerList(response))
}
function* sagaCreatePerformer({payload}) {
    let response = yield sd__createPerformer(payload)
    yield message.success(response)
}
function* renamePerformer({payload}) {
    let response = yield sd__renamePerformer(payload)
    yield message.success(response)
}



