import React, {useEffect} from "react";
import {PageHeader, Button, Tag, Typography, Row} from 'antd';
import {useDispatch, useSelector} from "react-redux";
import {PerformerTable} from "../components/table/PerformerTable";
import {getPerformerList} from "../redux/actions/performerActions";

export const PerformerPage = props => {
    const dispatch = useDispatch()
    let performerList = useSelector(state => state.performer.list)

    useEffect(() => {
        dispatch(getPerformerList())
    }, [])

    return (

    <PageHeader
        title="Альбомы"
        ghost={false}
        // onBack={() => window.history.back()}
        extra={[
            <Button key="create" type="primary">
                Создать
            </Button>,
        ]}
    >
            <PerformerTable performerList={performerList}/>
        </PageHeader>
    )
}
