import {Upload, Button, message} from 'antd';
import {UploadOutlined} from '@ant-design/icons';
import React, {useState} from "react";
import {useDispatch} from "react-redux";
import {updateCoverAlbum} from "../../redux/actions/albumActions";


export const EditAlbumCover = ({albumData}) => {
    const dispatch = useDispatch();
    const [fileList, setFileList] = useState([])
    const [uploading, setUploading] = useState(false)

    const handleUpload = () => {
        setUploading(true)
        dispatch(updateCoverAlbum({
            cover: fileList[0],
            idAlbum: albumData.idAlbum
        }))
        setTimeout(reset(), 15000)
    };

    const reset = () => {
        console.log('reset')
        setFileList([])
        setUploading(false)
    }

    const props = {
        onRemove: file => {
            setFileList([])
        },
        beforeUpload: file => {
            setFileList([file])
            return false;
        },
        fileList,
    };

    return (
        <div style={{display: "flex", marginTop: 16}}>
            <Upload {...props}>
                <Button>
                    <UploadOutlined/> Выбрать обложку
                </Button>
            </Upload>
            <Button
                type="primary"
                onClick={handleUpload}
                disabled={fileList.length === 0}
                style={{marginLeft: 16}}
            >
                {uploading ? 'Загруженно' : 'Загрузить'}
            </Button>
        </div>
    );
}
