import {albumAPI, performerAPI, propsAPI} from "./API";

export const sd__albumStatus = ({idAlbum, checked}) => {
    return albumAPI.setStatus({idAlbum, checked}).then(response => {
        return response;
    });
}

export const sd__trackPropNew = ({idTrack, idProp}) => {
    return propsAPI.trackPropNew({idTrack, idProp}).then(response => {
        return response;
    });
}

export const sd__createPerformer = ({name}) => {
    return performerAPI.create(name)
        .then(response => {
        return response;
    });
}

export const sd__renamePerformer = (data) => {
    return performerAPI.rename(data)
        .then(response => {
        return response;
    });
}
