import React, {useEffect, useState} from "react";
import {Button, Form, Input, InputNumber} from "antd";
import {EditAlbumCover} from "./EditAlbumCover";

export const EditAlbumForm = ({albumData, form, onFinish}) => {

    return (
        <>
            <Form form={form} name="horizontal_login" layout="inline" onFinish={onFinish}>
                <Form.Item
                    name="newNameAlbum"
                    rules={[{min: 4, message: 'Минимальная длина 4 символа'}]}
                >
                    <Input placeholder={albumData.name}/>
                </Form.Item>
                <Form.Item shouldUpdate={true}>
                    {() => (
                        <Button
                            type="primary"
                            htmlType="submit"
                            disabled={
                                !form.isFieldsTouched(true) ||
                                form.getFieldsError().filter(({errors}) => errors.length).length
                            }
                        >
                            Изменить название
                        </Button>
                    )}
                </Form.Item>
            </Form>
            <EditAlbumCover albumData={albumData}/>
            {/*<div>*/}
            {/*    <InputNumber placeholder={null} min={1980} max={2021} defaultValue={2021}/>*/}
            {/*    <Button*/}
            {/*        type="primary"*/}
            {/*        htmlType="submit"*/}
            {/*        disabled={true}*/}
            {/*    >*/}
            {/*        изменить год выпуска*/}
            {/*    </Button>*/}
            {/*</div>*/}
        </>
    )
}
