import {Modal, Button, Form} from 'antd';
import React, {useState} from "react";
import {CreatePropForm} from "../form/CreatePropsForm";
import {createNewUser} from "../../redux/actions/userActions";
import {useDispatch} from "react-redux";
import {createNewProps} from "../../redux/actions/propsActions";

export const CreatePropModal = ({visible, active, handleCancel, selectTypes}) => {
    const [form] = Form.useForm();
    const dispatch = useDispatch()

    const onCheck = async () => {
        try {
            const values = await form.validateFields();
            console.log('Success:', values);
            dispatch(createNewProps({values, active}))
            cancel();
        } catch (errorInfo) {
            console.log('Failed:', errorInfo);
        }
    };

    const cancel = () => {
        form.resetFields();
        handleCancel();
    }

    return (
            <Modal
                title="Добавить новое свойство"
                visible={visible}
                onOk={onCheck}
                onCancel={handleCancel}
                footer={[
                    <Button key="back" onClick={cancel}>
                        Отмена
                    </Button>,
                    <Button key="submit" type="primary"
                            onClick={onCheck}>
                        Создать
                    </Button>,
                ]}
            >
                <CreatePropForm
                    selectTypes={selectTypes}
                    form={form}/>
            </Modal>
    )
}
