import {takeEvery, put, select} from 'redux-saga/effects'
import {message} from 'antd';
import {
    ADD_TRACK_TO_PLAYLIST,
    CREATE_PLAYLIST, DELETE_PLAYLIST, DELETE_TRACK_PLAYLIST,
    GET_GENERAL_LIST, GET_OUTSIDE_SECTION_PLAYLIST,
    GET_PLAYLIST,
    RENAME_PLAYLIST,
    SET_PLAYLIST, UPDATE_ORDERS_PLAYLIST, UPDATE_PUBLIC_STATUS_PLAYLIST,
    UPLOAD_COVER_PLAYLIST
} from "../types";
import {
    addTrackToPlaylistAPI,
    createPlaylistAPI, deletePlaylistAPI, deleteTrackPlaylistAPI,
    getGeneralListAPI, getPlaylistAPI,
    renamePlaylistAPI,
    updateCoverPlaylistAPI, updateOrdersPlaylistAPI
} from "../../api/playlistAPI";
import {
    getGeneralPlaylist,
    getPlaylist,
    setGeneralPlaylist, setOutSideSectionPlaylist,
    setPlaylist,
    setPlaylistTracks
} from "../actions/playlistActions";
import {transformPlaylistList, transformTrackAlbum, transformTrackPlaylist} from "../../utils/transformAPI";
import {selectUploadTracks} from "../selectors";
import {playlistAPI} from "../../api/API";
import {onMessage} from "../../utils/onMessage";

export function* playlistSagaWatcher() {
    yield takeEvery(CREATE_PLAYLIST, create);
    yield takeEvery(RENAME_PLAYLIST, rename);
    yield takeEvery(UPLOAD_COVER_PLAYLIST, uploadCover);
    yield takeEvery(GET_GENERAL_LIST, getList);
    yield takeEvery(GET_PLAYLIST, getPlaylistSaga);
    yield takeEvery(ADD_TRACK_TO_PLAYLIST, addTrack);
    yield takeEvery(DELETE_TRACK_PLAYLIST, delTrack);
    yield takeEvery(DELETE_PLAYLIST, del);
    yield takeEvery(UPDATE_ORDERS_PLAYLIST, updateOrderPlaylist);
    yield takeEvery(GET_OUTSIDE_SECTION_PLAYLIST, getOutsideSectionPlaylist);
    yield takeEvery(UPDATE_PUBLIC_STATUS_PLAYLIST, updatePublicStatus);
}

function* create({payload}) {
    const idPlaylist = yield playlistAPI.create(payload);
    yield onMessage(idPlaylist);
    yield put(getGeneralPlaylist())
}

function* rename({payload}) {
    const response = yield renamePlaylistAPI(payload);
    yield message.success(response)
    // yield put(getGeneralPlaylist())
}

function* uploadCover(action) {
    const response = yield updateCoverPlaylistAPI(action);
    yield message.success(response)
}

function* getList() {
    const response = yield getGeneralListAPI()
    let data = yield transformPlaylistList(response)
    yield put(setGeneralPlaylist(data))
}

function* getOutsideSectionPlaylist({payload}) {
    const response = yield playlistAPI.OutsideSection(payload)
    console.log(payload);
    let data = yield transformPlaylistList(response)
    yield put(setOutSideSectionPlaylist(data))
}

function* getPlaylistSaga({payload}) {
    console.log('response', payload)
    const response = yield playlistAPI.getPlaylist(payload);

    // let data = yield transformPlaylistList(response)
    yield put(setPlaylist(response.data))
    let tracks = yield transformTrackPlaylist(response.data);
    console.log(tracks)
    yield put(setPlaylistTracks(tracks))
}

function* addTrack({payload}) {
    yield addTrackToPlaylistAPI(payload);
    // yield onMessage(response, 'трек успешно добавлен')
    yield put(getPlaylist(payload.idPlaylist))
}

function* delTrack({payload}) {
    const response = playlistAPI.DeleteTrack(payload.idTrackToPlaylist);
    yield onMessage(response);
    yield put(getPlaylist(payload.idPlaylist))
}

function* del({payload}) {
    const response = yield deletePlaylistAPI(payload.id);
    yield onMessage(response);
    yield put(getGeneralPlaylist())
}

function* updateOrderPlaylist({payload}) {
    let data = yield {"PlaylistOrderList": payload};
    yield updateOrdersPlaylistAPI(data);
}

function* updatePublicStatus({payload}) {
    console.log(payload)
    yield playlistAPI.UpdatePublicStatus(payload);
    yield put(getPlaylist(payload.idPlaylist))
}
