import React, {useEffect, useState} from "react";
import {Button, Card, Col, Grid, Modal, PageHeader, Row, Spin, Table} from "antd";
import {useDispatch, useSelector} from "react-redux";
import {getUserList, getUserType} from "../../redux/actions/userActions";
import {CreateUser} from "../user/CreateUser";
import {ButtonHeader} from "../interface/ButtonHeader";
const { useBreakpoint } = Grid;

export const UserList = () => {
    const screens = useBreakpoint();
    const [visible, setVisible] = useState(false)
    const {Column} = Table;
    const dispatch = useDispatch()
    let userList = useSelector(state => state.user.userList)
    let userType = useSelector(state => state.user.userType)
    let loading = useSelector(state => state.info.loading)

    useEffect(() => {
        dispatch(getUserList())
        dispatch(getUserType())
    }, [])

    const showModal = () => {
        setVisible(true)
    };

    const hideModal = () => {
        setVisible(false)
    };

    return (
        <Spin spinning={loading} tip="Loading...">
            <Row gutter={15}>
                <Col lg={24} xl={16}>
            <PageHeader
                ghost={false}
                style={{maxWidth: 800}}
                onBack={() => window.history.back()}
                title="Пользователи"
                extra={[
                    <ButtonHeader lable={'создать аккаунт'} hidden={screens.xl} action={showModal}  />
                ]}
            >
                <Table dataSource={userList}>
                    <Column title="Логин" dataIndex="login" key="login"/>
                    <Column title="Тип" dataIndex='typeAccount' key="type"/>
                </Table>
            </PageHeader>
                </Col>
                <Col lg={0} xl={8}>
                    <CreateUser visible={visible} hideModal={hideModal} userType={userType} />
                </Col>
            </Row>

        </Spin>
    )
}
