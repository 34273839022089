import React from "react";
import {Col, Input, Row, Skeleton, Tooltip, Typography} from "antd";
import { WarningFilled } from '@ant-design/icons';
import {convertHMS} from "../../utils/convertHMS";
import {NameRename} from "./NameRename";
// import {playPauseAudio} from "../../play/play";
const { Text, Paragraph } = Typography;

export const UploadTackList = React.memo(({newTracks, trackSave, trackDel}) => {

    let tracks = newTracks.map((track, i) => {
        let trackError = track.error;
        return (<Row key={track.idTrack} >
            <Col span={1}>{i+1}</Col>
            <Col span={1}>

                {trackError &&
                    <Tooltip title={track.error} color={'#ff0000'}>
                        <WarningFilled style={{ color: '#ff0000' }} />
                    </Tooltip> }
                {/*<div onClick={*/}
                {/*    () => {*/}
                {/*        playPauseAudio(track.idTrack, track.link)*/}
                {/*    }*/}
                {/*}><CaretRightFilled/></div>*/}
            </Col>
            <Col span={12}>
                {trackError
                    ?<Paragraph><Text type={"danger"}>{track.name}</Text></Paragraph>
                    :<NameRename name={track.name} id={track.idTrack} />}
            </Col>
            <Col span={2}>{convertHMS(track.duration)}</Col>
            <Col span={4}>{(track.size / 1048576).toFixed(1) + ` Мб`}</Col>
            <Col span={4}><a onClick={()=>{trackDel(track.idTrack)}}>удалить</a></Col>
        </Row>)
    })

    return (
            <div>
                {tracks}
            </div>
    )
})

// const track = newTracks.find(track => track.idTrack === event.currentTarget.id)
