import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {Button, Card, Col, Descriptions, PageHeader, Space, Spin, Table, Tag} from "antd";
import {deleteAlbum, getAlbumList, getAlbumListUnchecked} from "../../redux/actions/albumActions";
import {CreateAlbumModal} from "../modal/CreateAlbumModal";
import {NavLink} from "react-router-dom";

export const AlbumSectionList = ({albums, objectDelete}) => {

    const dispatch = useDispatch()

    const columns = [
        {
            title: 'id',
            dataIndex: 'idObject',
            key: 'idObject',
        },
        {
            title: 'Название',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: '',
            key: 'action',
            render: (text, record) => (
                <Space size="middle">
                    <a onClick={()=>objectDelete(record.idObject, record.idTypeCell)} key="delete" >
                        удалить
                    </a>
                </Space>
            ),
        },
    ];

    return <Table dataSource={albums}
                  columns={columns}
                  pagination={{ hideOnSinglePage: true }}
    />
}




