import React from "react";
import {Col, Row} from "antd";
import {NavLink} from "react-router-dom";

export const DropSectionItem = ({content, sectionDelete}) => {

return (
        <Row>
            <Col span={4}>{content.idSection}</Col>
            <Col span={14}><NavLink to={`/section/${content.idSection}`}>{content.name}</NavLink></Col>
            <Col span={6}><a onClick={()=>sectionDelete(content.idSection)} key="delete" >
                удалить
            </a></Col>
        </Row>
    );
};
