import React from "react";
import {Card, Col} from "antd";
import {ChangeAlbumStatus} from "./ChangeAlbumStatus";

const {Meta} = Card;

export const AlbumCover = ({albumData, albumType, status, onChangeChecked}) => {

    let typeAudio = () => {
        if (albumType === null) {
            return 'плейлист'
        } else {
            return albumType[albumData.idTypeAudio]
        }
    }

    return (
        <Card
            style={{maxWidth: 250}}
            cover={<img src={albumData.linkCover}/>}
            actions={[]}
        >
            <Meta/>
            <div style={{marginBottom: 20}}>
                <span><b>тип: </b>{typeAudio()}</span>
            </div>
            {albumType && <ChangeAlbumStatus isChecked={status} onChange={onChangeChecked}/>}
        </Card>
    )
}
