import {createStore, combineReducers, applyMiddleware, compose} from "redux";
import thunkMiddleware from 'redux-thunk';
import createSagaMiddleware from 'redux-saga'

import authReducer from "./reducer/authReducer";
import auditReducer from "./reducer/auditReducer";
import albumReducer from "./reducer/albumReducer";
import infoReducer from "./reducer/infoReducer";
import userReducer from "./reducer/userReducer";
import propsReducer from "./reducer/propsReducer";
import sectionReducer from "./reducer/sectionReducer";
import queueReducer from "./reducer/queueReducer";
import performerReducer from "./reducer/performerReducer";
import playlistReducer from "./reducer/playlistReducer";

import rootSaga from "./sagas/rootSagas";
import pageReducer from "./reducer/pageReducer";

const sagaMiddleware = createSagaMiddleware()

let reducers = combineReducers({
    auth: authReducer,
    album: albumReducer,
    user: userReducer,
    audit: auditReducer,
    info: infoReducer,
    props: propsReducer,
    queue: queueReducer,
    performer: performerReducer,
    playlist: playlistReducer,
    section: sectionReducer,
    page: pageReducer,
});

// let store = createStore(reducers, compose (
//     applyMiddleware(thunkMiddleware, sagaMiddleware),
//     window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
// ));

let store = createStore(reducers,
    applyMiddleware(thunkMiddleware, sagaMiddleware)
)

sagaMiddleware.run(rootSaga)

window.store = store;

export default store;
