import {useCallback, useEffect, useState} from "react";
import {usePlay} from "./usePlay";
import {useQueue} from "./useQueue";
import {useDispatch, useSelector} from "react-redux";
import {getQueue} from "../redux/actions/queueActions";

export const usePlayer = () => {
    const dispatch = useDispatch()
    const idQueue = useSelector(state => state.queue.idQueue)
    const [idQ, setIdQ] = useState(null)
    const {newTrack, playPause, checkTrack} = useQueue();

    function newQueue(idTrack, idAlbum) {
        newTrack(idTrack)
        setIdQ(idAlbum)
        // dispatch(getQueue(idAlbum))
        console.log(idAlbum, idQ, idQueue)
    }

    function checkAlbum(idTrack, idAlbum) {

    }

    const newAudio = (idTrack, idAlbum) => {
        console.log(idAlbum, idQ)
        if(idAlbum == idQ){
            checkTrack(idTrack)
            console.log(idTrack)
        }else{
            newQueue(idTrack, idAlbum)
        }
    };



    return {newAudio}
}
