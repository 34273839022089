import {SET_ERROR_MESSAGE, SET_LOADING_FALSE, SET_LOADING_TRUE, SET_MESSAGE} from "../types";

let initialState = {
    loading: false,
    message: '',
    errorMessage: ''
};

const albumReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_LOADING_FALSE:
            return {...state, loading: false}
        case SET_LOADING_TRUE:
            return {...state, loading: true}
        case SET_MESSAGE:
            return {...state, message: action.payload}
        case SET_ERROR_MESSAGE:
            return {...state, message: action.payload}
        default:
            return state;
    }
};

export default albumReducer;
